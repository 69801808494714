import PropTypes from "prop-types";
import React from 'react'


const SEO = ({ businessApiData }) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{businessApiData?.meta_title} </title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content={businessApiData?.meta_description} />
            <meta name="author" content={businessApiData?.meta_author} />
            <meta name="keywords" content={businessApiData?.meta_keyword} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


