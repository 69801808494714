import React from 'react';
import AccordionOne from '../accordion/AccordionOne';

const Mission = ({ faqsApiData }) => {
    const arrayLength = faqsApiData?.length;

    // Calculate the midpoint
    const midpoint = Math.ceil(arrayLength / 2);

    // Use slice to get the first and second halves
    const firstHalf = faqsApiData.slice(0, midpoint);
    const secondHalf = faqsApiData.slice(midpoint);


    return (
        <div className="rn-company-mission-are">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <AccordionOne faqsApiData={firstHalf} />
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" faqsApiData={secondHalf} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Mission;
