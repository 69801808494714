import React, { useState, useRef } from 'react';
import axios from "axios";
import Swal from "sweetalert2";


function ContactForm({ formStyle }) {
    const [loading, setLoading] = useState(false)
    const form = useRef();
    const onSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const { name, email, watsappnumber, message } = e.target.elements;

        let conFom = {
            name: name.value,
            email: email.value,
            whatsapp_num: watsappnumber.value,
            query_message: message.value,
        };

        // console.log("conFom: ", conFom);

        if (conFom.name !== "" && conFom.email !== "") {
            const url = 'https://cms.forexsupersignal.com/api/contact';
            let fData = new FormData();
            fData.append('name', name.value);
            fData.append('email', email.value);
            fData.append('whatsapp_num', watsappnumber.value);
            fData.append('query_message', message.value);
            setLoading(true)
            axios.post(url, fData).then((response) => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Submit Successfully',
                    showConfirmButton: true,
                    timer: 1500
                })
                form.current.reset();

            }).catch((error) => {
                setLoading(false)
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'something went wrong',
                })
            });
        } else {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'please fill all the field!',
            })
        }
    };

    return (
        <form className={`${formStyle}`} action="" id="contact_form"
            ref={form}
            onSubmit={onSubmit}>
            <div className="form-group">
                <h4>Contact Us</h4>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="watsappnumber"
                    placeholder="Phone Number"
                    required
                />
            </div>


            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">{loading ? "Submitting..." : "Submit Now"}</button>
            </div>

        </form>
    )
}
export default ContactForm;
