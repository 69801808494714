import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { IMAGE_BASE_URL } from "../../lib/api";

const ServiceList = [
  {
    image: "/images/service/icon-01.png",
    title: "Title One",
    description:
      "There are many variations variats  of passages of Lorem Ipsum available, but the majority.",
  },
  {
    image: "/images/service/icon-02.png",
    title: "Title Two",
    description:
      "Passages there are many variatin Lorem Ipsum available, but the majority have suffered.",
  },
  {
    image: "/images/service/icon-03.png",
    title: "Title Three",
    description:
      "Variations There are many of pass ages of Lorem Ipsum available, but the majority but the majority.",
  },
];
const ServiceThree = ({ textAlign, serviceStyle, howItSignupApiData }) => {
  return (
    <div className="row row--15 service-wrapper">
      {howItSignupApiData.slice(0, 3).map(
        (val, i) =>
          val?.isActive === 1 && (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className={`service ${serviceStyle} ${textAlign}`}>
                  <div className="inner">
                    <div className="image">
                      <img
                        src={`${IMAGE_BASE_URL + val?.image}`}
                        alt={val?.alt_tag}
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <Link
                          to="#service"
                          dangerouslySetInnerHTML={{ __html: val?.title }}
                        ></Link>
                      </h4>
                      <p
                        className="description b1 color-gray mb--0"
                        dangerouslySetInnerHTML={{ __html: val?.description }}
                      ></p>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          )
      )}
    </div>
  );
};
export default ServiceThree;
