import React, { useState, useEffect } from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import Separator from "../elements/separator/Separator";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import DataTable, { createTheme } from "react-data-table-component";
import ScrollAnimation from "react-animate-on-scroll";
import swal from "sweetalert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { referApi } from "../lib/api";
import { IMAGE_BASE_URL, BASE_URL } from "../lib/api";
import axios from "axios";
import { useSelector } from "react-redux";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceThree from "../elements/service/ServiceThree";
import { Link } from "react-router-dom";

const callToActionData = {
  title:
    "Navigating the Financial Markets: A Guide to Trading Strategies and Tactics",
  subtitle:
    "Explore the dynamic world of trading with our comprehensive guide. Learn effective trading strategies, understand market trends, and master the art of risk management. Whether you're a novice or experienced trader, discover key insights that can enhance your decision-making and propel you towards financial success in the fast-paced realm of trading.",
  btnText: "Contact Us",
};
const Refer = () => {
  const userData = useSelector((state) => state.userData);
  const [copied, setCopied] = useState(false);
  const [referalList, setReferalList] = useState()
  const [referalAmount, setReferalAmount] = useState()
  const [referApiData, setReferApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [upiId, setUpiId] = useState('');

  const [bankData, setBankData] = useState({
    amount: '',
    accountNumber: '',
    reEnterAccountNumber: '',
    ifscCode: '',
    accountHolderName: '',
  });
  // console.log("referalList: ", referalList);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [referData] = await Promise.all([referApi()]);

        // Updating state with the API data
        setReferApiData(referData[0]);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log("referApiData: ", referApiData);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(userData?.referral_id)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => console.error("Could not copy text: ", err));
  };


  // Define columns
  const columns = [
    { name: "Referral Name", selector: (row) => row.user.name, sortable: true },
    { name: "Date Of Join", selector: (row) => (row.created_at).substring(0, 10), sortable: true },
    { name: "Email ID", selector: (row) => row.user.email, sortable: true },
    {
      name: "Amount",
      cell: (row) => (
        <div
          style={{
            background: "#0ADC9C",
            border: "none",
            padding: "0.5em 1.5em",
            color: "#FFF",
            borderRadius: "5px",
          }}
        >
          {/* ₹{row.parent_point}.00 */}
          ₹80.00
        </div>
      ),
      button: true, // Indicate that this column contains a button
    },
  ];

  createTheme(
    "solarized",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "#0ADC9C",
      },
      background: {
        default: "#090F17",
      },
      context: {
        background: "linear-gradient(180deg,#102129,#244648)",
        text: "#FFFFFF",
      },
      divider: {
        default: "#090F17",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );
  const customStyles = {
    rows: {
      style: {
        background: "linear-gradient(180deg,#102129,#244648)",
        borderRadius: "10px",
        marginBottom: "6px",
      },
    },
    cells: {
      style: {
        "&:nth-of-type(-n+2)": {
          // Apply to the first 7 cells of each row
          paddingRight: 0, // Remove default padding
          "&:after": {
            // Use a pseudo-element for the divider
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            borderRight: "2px dotted #0ADC9C",
          },
        },
      },
    },
    headCells: {
      style: {
        "&:nth-of-type(-n+2)": {
          // Apply to the first 7 cells of each row
          paddingRight: 0, // Remove default padding
          "&:after": {
            // Use a pseudo-element for the divider
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            borderRight: "2px dotted #0ADC9C", // Adjust as needed
          },
        },
        marginBottom: "10px",
      },
    },
  };


  useEffect(() => {
    const userToken = localStorage.getItem("usertoken");
    // Axios request configuration
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: `${BASE_URL}/user/list`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    // Make the API request using Axios
    axios(axiosConfig)
      .then((res) => {
        // Handle the response
        // setApiResponse(response.data);
        setReferalList(res.data)
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const userToken = localStorage.getItem("usertoken");
    // Axios request configuration
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: `${BASE_URL}/user/refer/point`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    // Make the API request using Axios
    axios(axiosConfig)
      .then((res) => {
        // Handle the response
        // setApiResponse(response.data);
        setReferalAmount(res.data)
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the corresponding state based on the input field's name
    if (name === 'amount') {
      setAmount(value);
    } else if (name === 'upiId') {
      setUpiId(value);
    }
  };

  // Event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Access the values from the state
    // console.log('Amount:', amount);
    // console.log('UPI ID:', upiId);

    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: `${BASE_URL}/upi/payment/status`,
      data: {
        request_amount: amount,
        upi_id: upiId
      }
    };

    if (amount !== "" && upiId !== "") {
      if (referalAmount?.parent_user_id >= amount) {
        setLoading(true)
        axios(axiosConfig)
          .then((res) => {
            console.log("upi request: ", res.data);
            setLoading(false)
            swal(
              "Withdrow Request",
              "Your Withdrow Request successfully Send!",
              "success"
            );
          })
          .catch((error) => {
            // Handle errors
            setLoading(false)
            console.error("Error fetching data:", error);
          });
      } else {
        setLoading(false)
        swal("Insufficient balance", "Your balance is Insufficient", "error");
      }

    } else {
      setLoading(false)
      swal("Oops", "Please fill all the field", "error");
    }


    // Perform any other actions you need with the values
  };

  const handleBankChange = (e) => {
    const { name, value } = e.target;
    setBankData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBankSubmit = (e) => {
    e.preventDefault();
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: `${BASE_URL}/upi/payment/status`,
      data: {
        amount: bankData.amount,
        account_num: bankData.accountNumber,
        again_account_num: bankData.reEnterAccountNumber,
        ifsc_code: bankData.ifscCode,
        account_holder: bankData.accountHolderName,
      }
    };

    if (bankData.amount !== "" && bankData.accountHolderName !== "" && bankData.accountNumber !== "" && bankData.ifscCode !== "") {
      if (bankData.accountNumber === bankData.reEnterAccountNumber) {
        if (referalAmount?.parent_user_id >= bankData.amount) {
          setLoading(true)
          axios(axiosConfig)
            .then((res) => {
              setLoading(false)
              // console.log("upi request: ", res.data);
              swal(
                "Withdrow Request",
                "Your Withdrow Request successfully Send!",
                "success"
              );
              setBankData({
                amount: '',
                accountNumber: '',
                reEnterAccountNumber: '',
                ifscCode: '',
                accountHolderName: '',
              })
            })
            .catch((error) => {
              // Handle errors
              setLoading(false)
              console.error("Error fetching data:", error);
            });
        } else {
          setLoading(false)
          swal("Insufficient balance", "Your balance is Insufficient ", "error");
        }
      } else {
        setLoading(false)
        swal("oop!", "Account number is not matched", "error");
      }

    } else {
      setLoading(false)
      swal("Oops", "Please fill all the field", "error");
    }

  };


  return (
    <>
      <SEO title="Refer" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small round btn-icon" />
        {/* Start Slider area  */}
        <div
          className="slider-area slider-style-2 variation-2  height-750 bg_image"
          style={{
            backgroundImage: `url(${IMAGE_BASE_URL + referApiData?.banner_image
              }`,
            height: "50vh",
          }}
        ></div>
        {/* End Slider area  */}
        <Separator />
        <div
          className="rn-callto-action bg_image bg_image_fixed"
          style={{
            backgroundImage: `url(${IMAGE_BASE_URL + referApiData?.cta_img}`,
            // height: "50vh",
          }}
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  {/* <div className="content text-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <h2>{referApiData?.title}:</h2>
                      <h2>
                      </h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <p>{referApiData?.short_des}</p>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <div className="call-to-btn">
                        <h2 className="title">
                          {referApiData?.last_line}
                        </h2>
                      </div>
                    </ScrollAnimation>
                  </div> */}
                  {/* Start Service Area  */}
                  <div className="rn-service-area rn-section-gap">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle={referApiData?.heading}
                            title={referApiData?.heading}
                            description={referApiData?.short_description}
                          />
                        </div>
                      </div>
                      <div className="row row--15 service-wrapper">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <div className={`service service__style--2 text-center`}>
                              <div className="inner">
                                <div className="image">
                                  <img
                                    src={`${IMAGE_BASE_URL + referApiData?.image_one}`}
                                    alt={referApiData?.alt_tag_one}
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="title">
                                    <Link
                                      to="#service"
                                      dangerouslySetInnerHTML={{ __html: referApiData?.title_one }}
                                    ></Link>
                                  </h4>
                                  <p
                                    className="description b1 color-gray mb--0"
                                    dangerouslySetInnerHTML={{ __html: referApiData?.short_des_one }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </ScrollAnimation>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <div className={`service service__style--2 text-center`}>
                              <div className="inner">
                                <div className="image">
                                  <img
                                    src={`${IMAGE_BASE_URL + referApiData?.image_two}`}
                                    alt={referApiData?.alt_tag_two}
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="title">
                                    <Link
                                      to="#service"
                                      dangerouslySetInnerHTML={{ __html: referApiData?.title_two }}
                                    ></Link>
                                  </h4>
                                  <p
                                    className="description b1 color-gray mb--0"
                                    dangerouslySetInnerHTML={{ __html: referApiData?.short_des_two }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </ScrollAnimation>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <div className={`service service__style--2 text-center`}>
                              <div className="inner">
                                <div className="image">
                                  <img
                                    src={`${IMAGE_BASE_URL + referApiData?.image_three}`}
                                    alt={referApiData?.alt_tag_three}
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="title">
                                    <Link
                                      to="#service"
                                      dangerouslySetInnerHTML={{ __html: referApiData?.title_three }}
                                    ></Link>
                                  </h4>
                                  <p
                                    className="description b1 color-gray mb--0"
                                    dangerouslySetInnerHTML={{ __html: referApiData?.short_des_three }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Service Area  */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Separator />

        {/* ======Start Earning Section======== */}

        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-md-6">
              <div className="inner text-start">
                <div dangerouslySetInnerHTML={{ __html: referApiData?.invite_friend }}></div>
                <div
                  className="row align-items-center"
                  style={{
                    borderBottom: "5px solid #090F17",
                    background: "linear-gradient(180deg,#102129,#244648)",
                  }}
                >
                  <div className="col-4 " style={{ height: "15px" }}>
                    <h6 className="d-flex align-items-center">
                      Your Referal ID :
                    </h6>
                  </div>
                  <div className="col-4 " style={{ height: "18px" }}>
                    <h5>{userData?.referral_id}</h5>
                  </div>

                  <button
                    onClick={() => copyToClipboard()}
                    className="col-4"
                    style={{
                      background: "#0ADC9C",
                      border: "none",
                      padding: "0.5em 1em",
                      color: "#FFF",
                    }}
                  >
                    {copied ? "copied!" : "Copy Code"}
                  </button>
                </div>
                {/* <h1 className="title theme-gradient">
                  What is Lorem Ipsum?
                </h1>
                <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="row col-lg-12 pt-4 ml--0"
                style={{
                  background: "linear-gradient(180deg,#102129,#244648)",
                }}
              >
                <div className="col-md-6 row">
                  <h6 className="col-6">Total Referal :</h6>
                  <h5 className="col-6">{referalAmount?.user}</h5>
                </div>
                <div className="col-md-6 row">
                  <h6 className="col-7">Total Earning :</h6>
                  <h5 className="col-5">₹{referalAmount?.coin}.00</h5>
                </div>
              </div>

              <DataTable
                // title={tableTitle}
                columns={columns}
                data={referalList?.user}
                pagination
                theme="solarized"
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>

        {/* <div className="earning-section" style={{ backgroundColor: "#090F17" }}>
          <div className="container">
            <div className="row pt--50 justify-content-center">
              <div
                className="col-lg-8 mr--10"
                style={{
                  background: "linear-gradient(180deg,#102129,#244648)",
                }}
              >
                <div
                  className="row align-items-center"
                  style={{
                    borderBottom: "5px solid #090F17",
                    background: "linear-gradient(180deg,#102129,#244648)",
                  }}
                >
                  <div className="col-4 " style={{ height: "15px" }}>
                    <h6 className="d-flex align-items-center">
                      Your Referal ID :
                    </h6>
                  </div>
                  <div className="col-4 " style={{ height: "18px" }}>
                    <h5>{userData?.referral_id}</h5>
                  </div>

                  <button
                    onClick={() => copyToClipboard()}
                    className="col-4"
                    style={{
                      background: "#0ADC9C",
                      border: "none",
                      padding: "0.5em 1em",
                      color: "#FFF",
                    }}
                  >
                    {copied ? "copied!" : "Copy Code"}
                  </button>
                </div>
                <div className="pt--15">
                  <h6>Total Referal :</h6>
                  <h5>{referalAmount?.user}</h5>
                </div>
              </div>
              <div
                className="col-lg-3 p-5"
                style={{
                  background: "linear-gradient(180deg,#102129,#244648)",
                }}
              >
                <h6>Total Earning :</h6>
                <h5>₹{referalAmount?.coin}.00</h5>
              </div>
            </div>
          </div>
        </div> */}
        {/* ======End Earning Section======== */}

        {/* start amount withdow */}
        <div style={{ backgroundColor: "#090F17" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--50">
                <Tabs>
                  <div className="row row--30 align-items-center justify-content-center">
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                      <div className="rn-default-tab">
                        <div className="tab-button-panel">
                          <TabList className="tab-button">
                            <Tab>
                              <div className="rn-tab-button">
                                <button>UPI Transfer</button>
                              </div>
                            </Tab>
                            <Tab>
                              <div className="rn-tab-button">
                                <button>Bank Transfer</button>
                              </div>
                            </Tab>
                            {/* <Tab>
                              <div className="rn-tab-button">
                                <button>Transaction History</button>
                              </div>
                            </Tab> */}
                          </TabList>
                        </div>

                        <div className="tab-content-panel">
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <form onSubmit={handleSubmit}>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="amount"
                                      placeholder="Amount"
                                      value={amount}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="upiId"
                                      placeholder="Enter UPI ID"
                                      value={upiId}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <button type="submit" className="btn-default btn-sm">
                                      {loading ? "Sending..." : "SEND"}

                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </TabPanel>

                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <form onSubmit={handleBankSubmit}>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="amount"
                                      placeholder="Amount"
                                      required
                                      value={bankData.amount}
                                      onChange={handleBankChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="accountNumber"
                                      placeholder="Bank account number"
                                      required
                                      value={bankData.accountNumber}
                                      onChange={handleBankChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="reEnterAccountNumber"
                                      placeholder="Re-enter bank account number"
                                      required
                                      value={bankData.reEnterAccountNumber}
                                      onChange={handleBankChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="ifscCode"
                                      placeholder="IFSC code"
                                      required
                                      value={bankData.ifscCode}
                                      onChange={handleBankChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="accountHolderName"
                                      placeholder="Bank account holder's name"
                                      required
                                      value={bankData.accountHolderName}
                                      onChange={handleBankChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <button className="btn-default btn-sm" type="submit">
                                      {loading ? "Sending..." : "SEND"}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="rn-tab-content">
                              <div className="inner">
                                <p>
                                  Exercitationem Ipsum lorem dolor sit amet
                                  consectetur, adipisicing elit. Quibusdam ipsa,
                                  deleniti soluta minima minus asperiores
                                  doloribus enim vitae obcaecati fuga assumenda
                                  laudantium nemo odio provident nulla tempore
                                  corrupti! Nemo.
                                </p>

                                <p>
                                  Ipsa Quibusdam, deleniti soluta minima minus
                                  asperiores doloribus enim vitae obcaecati fuga
                                  assumenda laudantium nemo odio provident nulla
                                  exercitationem tempore corrupti! Nemo.
                                </p>
                              </div>
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* end amount withdow */}

        {/* Start Elements Area  */}
        {/* <div
          className="rwt-pricing-area rn-section-gap"
          id="pricePlanCustom"
          style={{ backgroundColor: "#090F17" }}
        >
          <div className="container">
            <DataTable
              // title={tableTitle}
              columns={columns}
              data={referalList?.user}
              pagination
              theme="solarized"
              customStyles={customStyles}
            />
          </div>
        </div> */}
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Refer;
