import { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const AdsPopup = () => {
  const [modalShow, setModalShow] = useState(true);

  const handleAllowClick = () => {
    // Set a flag in local storage to indicate that cookies are allowed
    localStorage.setItem("cookiesAccepted", "true");

    // Close the modal
    setModalShow(false);
  };

  // Check if cookies have been accepted before showing the modal
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");

    if (cookiesAccepted === "true") {
      setModalShow(false);
    }
  }, []);

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        style={{ zIndex: 99999999 }}
      >
        <Modal.Body>
          <div className="cookies-box">
            <h3 className="cookies__title">This website uses cookies</h3>
            <p className="cookies__text">
              We use cookies to personalize content and ads, to provide social
              media features, and to analyze our traffic. We also share
              information about your use of our site with our social media,
              advertising, and analytics partners who may combine it with other
              information that you’ve provided to them or that they’ve collected
              from your use of their services.
            </p>
            <div className="cookies__buttons">
              <button
                id="acceptBtn"
                className="cookies__btn cookies__btn--accept"
                onClick={handleAllowClick}
              >
                Allow
              </button>
              <button
                className="cookies__btn cookies__btn--decline"
                onClick={() => setModalShow(false)}
              >
                Deny
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdsPopup;
