import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { IMAGE_BASE_URL } from "../../lib/api";

const ServiceTwo = ({ howItWorkApiData, textAlign, cardStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {howItWorkApiData?.map((val, i) => (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`card-box ${cardStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <Link to="#service">
                    <img
                      src={`${IMAGE_BASE_URL}${val?.image}`}
                      alt={val?.alt_tag}
                    />
                  </Link>
                </div>
                <div className="content">
                  <h4 className="title mb--20">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val?.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val?.description }}
                  ></p>
                  {/* <Link className="btn-default btn-small btn-border" to="#service">Read More</Link> */}
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceTwo;
