import React, { useState, useEffect } from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import Separator from "../elements/separator/Separator";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import BlogPropTwo from "../components/blog/itemProp/BlogPropTwo";
import { homeApi } from "../lib/api";
const Blog = () => {
  const [homeApiData, setHomeApiData] = useState([]);

  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      try {
        // Calling businessApi and homeApi simultaneously
        const [homeData] = await Promise.all([homeApi()]);

        // Updating state with the API data
        setHomeApiData(homeData[0]);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <SEO title="Blog" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        <Separator />
        <BreadcrumbOne
          title={homeApiData?.blog_title}
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Blog List View"
        />
        <div className="main-content">
          {/* Start Blog Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <BlogPropTwo
                  column="col-lg-4 mt--30"
                  StyleVarProp="box-card-style-default card-list-view"
                />
              </div>
            </div>
          </div>
          {/* End Blog Area  */}
        </div>
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Blog;
