import React, { useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PricingThree = ({
  modalShowSignIn,
  modalHidefun,
  handleSignInModal,
  packageSubscriptionApiData,
  packageTrialApiData,
}) => {
  const loginAuth = useSelector((state) => state.loginAuth);
  const userData = useSelector((state) => state.userData);
  // console.log("userData: ", userData);

  const handleSignIn = async () => {
    handleSignInModal();
  };

  return (
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="row row--0">
          {/* Start PRicing Table Area  */}
          <div className="col-lg-6 col-md-6 col-12">
            <div className="rn-pricing style-2">
              <div
                className="pricing-table-inner"
                style={{ backgroundColor: "#244648" }}
              >
                <div className="pricing-header">
                  <h4 className="title">{packageTrialApiData?.package_name}</h4>
                  <div className="pricing">
                    <div className="price-wrapper">
                      <span className="currency">₹</span>
                      <span className="price">
                        {packageTrialApiData?.package_price}
                      </span>
                    </div>
                    <span className="subtitle">
                      {packageTrialApiData?.package_days}
                    </span>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-style--1">
                    {packageTrialApiData.feature?.map((ele, id) => {
                      return (
                        <li key={id}>
                          <FiCheck />
                          {ele?.package_feature}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="pricing-footer">
                  {loginAuth ? (
                    <a style={{ color: "#0ADC9C" }}>
                      {userData?.free_trial_status ? "Congratulations, you get a 7-day free trial!" : ""}

                    </a>
                  ) : (
                    <a
                      style={{ cursor: "pointer" }}
                      className="btn-default btn-border"
                      onClick={() => handleSignIn()}
                    >
                      Free Trial
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* End PRicing Table Area  */}

          {/* Start PRicing Table Area  */}
          <div className="col-lg-6 col-md-6 col-12">
            <div className="rn-pricing style-2 active">
              <div className="pricing-table-inner">
                <div className="pricing-header">
                  <h4 className="title">
                    {packageSubscriptionApiData?.package_name}
                  </h4>
                  <div className="pricing">
                    <div className="price-wrapper">
                      <span className="currency">₹</span>
                      <span className="price">
                        {packageSubscriptionApiData?.package_price}
                      </span>
                    </div>
                    <span className="subtitle">
                      {packageSubscriptionApiData?.package_days}
                    </span>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-style--1">
                    {packageSubscriptionApiData.feature?.map((ele, id) => {
                      return (
                        <li key={id}>
                          <FiCheck />
                          {ele?.package_feature}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {loginAuth ? (
                  <div className="pricing-footer">
                    <Link className="btn-default" to="/checkout">
                      Purchase Now
                    </Link>
                  </div>
                ) : (
                  <div className="pricing-footer">
                    <Link
                      className="btn-default"
                      to={"#"}
                      onClick={() => handleSignIn()}
                    >
                      Purchase Now
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* End PRicing Table Area  */}
        </div>
      </div>
    </div>
  );
};
export default PricingThree;
