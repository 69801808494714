import React, { useState, useEffect } from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import Separator from "../elements/separator/Separator";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import DataTable, { createTheme } from "react-data-table-component";
import SectionTitleTwo from "../elements/sectionTitle/SectionTitleTwo";
import { signalApi, allHeadingApi, IMAGE_BASE_URL } from "../lib/api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// ------------------------

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Signal = () => {
  const userData = useSelector((state) => state.userData);
  const [signalApiData, setSignalApiData] = useState([]);
  const [allHeadingApiData, setAllHeadingApiData] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [tableTitle, setTableTitle] = useState("BUY");
  const [activeColorOne, setActiveColorOne] = useState("btn-default-three");
  const [activeColorTwo, setActiveColorTwo] = useState("btn-default");
  const [buyFilter, setBuyFilter] = useState([]);
  // console.log("signalApiData: ", signalApiData);
  // console.log("filteredArray: ", filteredArray);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [signalData, allHeadingData] = await Promise.all([
          signalApi(),
          allHeadingApi(),
        ]);
        setSignalApiData(signalData);
        setAllHeadingApiData(allHeadingData[0]);
        const filteredData = await signalData.filter(
          (item) => item.buy_sell === "Buy"
        );
        setFilteredArray(filteredData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData(); // Fetch data initially

    const intervalId = setInterval(fetchData, 2000); // Polling interval: 1 minute (adjust as needed)

    return () => clearInterval(intervalId); // Cleanup function to clear interval on component unmount
  }, []);



  const hadleBuySignals = async (e) => {
    e?.preventDefault();
    const filteredData = await signalApiData.filter(
      (item) => item.buy_sell === "Buy"
    );
    setFilteredArray(filteredData);
    setTableTitle("BUY");
    setActiveColorOne("btn-default-three");
    setActiveColorTwo("btn-default");
  };
  const hadleSellSignals = async (e) => {
    e.preventDefault();
    const filteredData = await signalApiData.filter(
      (item) => item.buy_sell === "Sell"
    );
    setFilteredArray(filteredData);
    setTableTitle("SELL");
    setActiveColorOne("btn-default");
    setActiveColorTwo("btn-default-three");
  };

  useEffect(() => {
    hadleBuySignals()
  }, [])




  const MultiLineHeader = ({ text }) => {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  // Define columns
  const columns = [
    { name: "Stocks", selector: (row) => row.stocks, sortable: true },
    {
      name: "Buy/Sell",

      cell: (row) => (
        <>
          <button
            style={{
              background: row.buy_sell === "BUY" ? "#0ADC9C" : "red",
              border: "none",
              padding: "0.5em 1.5em",
              color: "#FFF",
              borderRadius: "5px",
              cursor: "default",
            }}
          >
            {row.buy_sell}
          </button>
        </>
      ),
      // button: "true", // Indicate that this column contains a button
    },
    {
      name: <MultiLineHeader text="Signal Started<br/>Date-Time" />,
      selector: (row) => {
        const date = new Date(row.created_at);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return <div dangerouslySetInnerHTML={{ __html: `${day}-${month}-${year}<br />${hours}:${minutes}:${seconds}` }} />;
      },
      sortable: true,
    },
    { name: "Entry", selector: (row) => row.signal_entry, sortable: true },
    { name: "TP1", selector: (row) => row.tp1, sortable: true },
    { name: "TP2", selector: (row) => row.tp2, sortable: true },
    { name: "TP3", selector: (row) => row.tp3, sortable: true },
    { name: "Stop Less", selector: (row) => row.stop_loss, sortable: true },
    {
      name: "Prediction",

      cell: (row) => (
        <>
          <button
            style={{
              background: row.isActive === 1 ? "#0ADC9C" : "red",
              border: "none",
              padding: "0.5em 1.5em",
              color: "#FFF",
              borderRadius: "5px",
              cursor: "default",
            }}
          >
            {row.isActive === 1 ? "Active" : "Close"}
          </button>
          {row.isColor === 1 ? (
            <span
              style={{
                backgroundColor: "#0ADC9C",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                marginLeft: "18px",
              }}
            />
          ) : row.isColor === 0 ? (
            <span
              style={{
                backgroundColor: "red",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                marginLeft: "18px",
              }}
            />
          ) : (
            ""
          )}
        </>
      ),
      // button: "true", // Indicate that this column contains a button
    },
  ];
  // Function to handle the action button click
  const handleAction = (row) => {
    // Implement your action logic here
    console.log(`Action clicked for row with ID: ${row.id}`);
  };
  createTheme(
    "solarized",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "#0ADC9C",
      },
      background: {
        default: "#090F17",
      },
      context: {
        background: "linear-gradient(180deg,#102129,#244648)",
        text: "#FFFFFF",
      },
      divider: {
        default: "#090F17",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );
  const customStyles = {
    rows: {
      style: {
        background: "linear-gradient(180deg,#102129,#244648)",
        borderRadius: "10px",
        marginBottom: "6px",
      },
    },
    cells: {
      style: {
        "&:nth-of-type(-n+8)": {
          // Apply to the first 7 cells of each row
          paddingRight: 0, // Remove default padding
          "&:after": {
            // Use a pseudo-element for the divider
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            borderRight: "2px dotted #0ADC9C", // Adjust as needed
          },
        },
      },
    },
    headCells: {
      style: {
        "&:nth-of-type(-n+8)": {
          // Apply to the first 7 cells of each row
          paddingRight: 0, // Remove default padding
          "&:after": {
            // Use a pseudo-element for the divider
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            borderRight: "2px dotted #0ADC9C", // Adjust as needed
          },
        },
        marginBottom: "10px",
      },
    },
  };
  console.log("signalApiData: ", signalApiData);
  return (
    <>
      <SEO title="Signal" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small round btn-icon" />
        {/* Start Slider area  */}
        <div
          className="slider-area slider-style-2 variation-2  height-750 bg_image"
          style={{
            height: "30vh",
            backgroundImage: `url(${IMAGE_BASE_URL + allHeadingApiData?.signal_banner
              }`,
          }}
        >
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-6">
                <div className="inner text-start">
                  <h1 className="title display-one">We provide <br /> reliable business <br /> consulting help.</h1>
                  <h6 className="tag-title">SOLUTION FOR YOUR BUSINESS</h6>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* End Slider area  */}
        <Separator />

        {/* Start Elements Area  */}
        <div
          className="rwt-pricing-area rn-section-gap"
          id="pricePlanCustom"
          style={{ backgroundColor: "#090F17" }}
        >
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitleTwo
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="OUR PRICING PLAN"
                  title="FOREX SUPER SIGNALS"
                // description="We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials."
                />
                {/* btn-default */}
                {/* btn -default-three */}
              </div>
              {/* <div className="button-group mt--40 text-center">
                <a
                  className={`${activeColorOne} btn-small btn-icon`}
                  href="#"
                  onClick={(e) => hadleBuySignals(e)}
                >
                  BUY <i className="icon"></i>
                </a>
                <a
                  className={`${activeColorTwo} btn-small`}
                  href="#"
                  onClick={(e) => hadleSellSignals(e)}
                >
                  SELL <i className="icon"></i>
                </a>
              </div> */}
            </div>
            {userData.free_trial_status === 1 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th>Stocks</Th>
                    <Th>Buy/Sell</Th>
                    <Th>Signal Started <br />Date-Time</Th>
                    <Th>Entry</Th>
                    <Th>TP1</Th>
                    <Th>TP2</Th>
                    <Th>TP3</Th>
                    <Th>Stop Less</Th>
                    <Th>Prediction</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    signalApiData?.map((ele, index) => {
                      const date = new Date(ele?.created_at);
                      const day = date.getDate().toString().padStart(2, '0');
                      const month = (date.getMonth() + 1).toString().padStart(2, '0');
                      const year = date.getFullYear();
                      const hours = date.getHours().toString().padStart(2, '0');
                      const minutes = date.getMinutes().toString().padStart(2, '0');
                      const seconds = date.getSeconds().toString().padStart(2, '0');
                      return (
                        <Tr key={index}>
                          <Td>{ele?.stocks}</Td>
                          <Td><button
                            style={{
                              background: ele?.buy_sell === "Buy" ? "#0ADC9C" : "red",
                              border: "none",
                              padding: "0.5em 1.5em",
                              color: "#FFF",
                              borderRadius: "5px",
                              cursor: "default",
                            }}
                          >
                            {ele?.buy_sell}
                          </button></Td>
                          <Td>
                            <div dangerouslySetInnerHTML={{ __html: `${day}-${month}-${year}<br />${hours}:${minutes}:${seconds}` }} />
                          </Td>
                          <Td>{ele?.signal_entry}</Td>
                          <Td>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>{ele?.tp1}</div>
                              <div>
                                {ele?.isColor === 1 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#0ADC9C",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : ele?.isColor === 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "red",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Td>
                          <Td>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>{ele?.tp2}</div>
                              <div>
                                {ele?.isTp1 === 1 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#0ADC9C",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : ele?.isTp1 === 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "red",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Td>
                          <Td>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>{ele?.tp3}</div>
                              <div>
                                {ele?.isTp2 === 1 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#0ADC9C",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : ele?.isTp2 === 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "red",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Td>
                          <Td>{ele?.stop_loss}</Td>
                          <Td><button
                            style={{
                              background: ele?.isActive === 1 ? "#0ADC9C" : "red",
                              border: "none",
                              padding: "0.5em 1.5em",
                              color: "#FFF",
                              borderRadius: "5px",
                              cursor: "default",
                            }}
                          >
                            {ele?.isActive === 1 ? "Active" : "Close"}
                          </button></Td>

                        </Tr>
                      )
                    })
                  }

                  {/* <Tr>
                                <Td>Capstone Data</Td>
                                <Td>19 May 2019</Td>
                                <Td>205 Gorgas</Td>
                              </Tr>
                              <Tr>
                                <Td>Tuscaloosa D3</Td>
                                <Td>29 June 2019</Td>
                                <Td>Github</Td>
                              </Tr> */}
                </Tbody>
              </Table>
            ) : userData.subscribed_status === 1 || userData.free_trial_status === 1 ? (
              // <DataTable
              //   responsive={true}
              //   // title={tableTitle}
              //   columns={columns}
              //   data={signalApiData}
              //   pagination
              //   theme="solarized"
              //   customStyles={customStyles}
              // />
              <Table>
                <Thead>
                  <Tr>
                    <Th>Stocks</Th>
                    <Th>Buy/Sell</Th>
                    <Th>Signal Started <br />Date-Time</Th>
                    <Th>Entry</Th>
                    <Th>TP1</Th>
                    <Th>TP2</Th>
                    <Th>TP3</Th>
                    <Th>Stop Less</Th>
                    <Th>Prediction</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    signalApiData?.map((ele, index) => {
                      const date = new Date(ele?.created_at);
                      const day = date.getDate().toString().padStart(2, '0');
                      const month = (date.getMonth() + 1).toString().padStart(2, '0');
                      const year = date.getFullYear();
                      const hours = date.getHours().toString().padStart(2, '0');
                      const minutes = date.getMinutes().toString().padStart(2, '0');
                      const seconds = date.getSeconds().toString().padStart(2, '0');
                      return (
                        <Tr key={index}>
                          <Td>{ele?.stocks}</Td>
                          <Td><button
                            style={{
                              background: ele?.buy_sell === "Buy" ? "#0ADC9C" : "red",
                              border: "none",
                              padding: "0.5em 1.5em",
                              color: "#FFF",
                              borderRadius: "5px",
                              cursor: "default",
                            }}
                          >
                            {ele?.buy_sell}
                          </button></Td>
                          <Td>
                            <div dangerouslySetInnerHTML={{ __html: `${day}-${month}-${year}<br />${hours}:${minutes}:${seconds}` }} />
                          </Td>
                          <Td>{ele?.signal_entry}</Td>
                          <Td>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>{ele?.tp1}</div>
                              <div>
                                {ele?.isColor === 1 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#0ADC9C",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : ele?.isColor === 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "red",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Td>
                          <Td>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>{ele?.tp2}</div>
                              <div>
                                {ele?.isTp1 === 1 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#0ADC9C",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : ele?.isTp1 === 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "red",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Td>
                          <Td>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>{ele?.tp3}</div>
                              <div>
                                {ele?.isTp2 === 1 ? (
                                  <div
                                    style={{
                                      backgroundColor: "#0ADC9C",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : ele?.isTp2 === 0 ? (
                                  <div
                                    style={{
                                      backgroundColor: "red",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      marginLeft: "18px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Td>
                          <Td>{ele?.stop_loss}</Td>
                          <Td><button
                            style={{
                              background: ele?.isActive === 1 ? "#0ADC9C" : "red",
                              border: "none",
                              padding: "0.5em 1.5em",
                              color: "#FFF",
                              borderRadius: "5px",
                              cursor: "default",
                            }}
                          >
                            {ele?.isActive === 1 ? "Active" : "Close"}
                          </button></Td>

                        </Tr>
                      )
                    })
                  }

                  {/* <Tr>
                    <Td>Capstone Data</Td>
                    <Td>19 May 2019</Td>
                    <Td>205 Gorgas</Td>
                  </Tr>
                  <Tr>
                    <Td>Tuscaloosa D3</Td>
                    <Td>29 June 2019</Td>
                    <Td>Github</Td>
                  </Tr> */}
                </Tbody>
              </Table>
            ) : (
              <>
                <h1 className="text-center">Your package has expired</h1>
                <div className="d-flex align-content-center justify-content-center">
                  <Link
                    className={`btn-default btn-small btn-icon`}
                    to="/price"
                  >
                    Sunbscrib Now ! <i className="icon"></i>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Signal;
