import React, { useState, useEffect } from "react";
import BlogClassicData from "../data/blog/BlogList.json";
import BlogDetailsContent from "../components/blog/BlogDetailsContent";
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import { blogApi } from "../lib/api";

import { useParams } from "react-router-dom";
const BlogDetails = (params) => {
  const [blogTagArray, setBlogTagArray] = useState([]);
  const [blogApiData, setBlogApiData] = useState([]);
  const { blog_slug } = useParams();
  const blogFilter = blogApiData.find((blog) => blog.blog_slug === blog_slug);
  //   console.log("blogTagArray: ", blogTagArray);

  useEffect(() => {
    // Convert the string to an array when the component mounts
    setBlogTagArray(blogFilter?.blog_tag.split(","));
  }, [blogFilter]);

  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      try {
        // Calling businessApi and homeApi simultaneously
        const [blogData] = await Promise.all([blogApi()]);

        // Updating state with the API data
        setBlogApiData(blogData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);

  if (!blogFilter) {
    return <div>Blog not found</div>;
  }
  return (
    <>
      <SEO title="Blog Details" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        <div className="rn-blog-details-area">
          <BlogDetailsContent
            blogFilter={blogFilter}
            blogTagArray={blogTagArray}
          />
        </div>
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default BlogDetails;
