import React from "react";
import SidebarTag from "./sidebar/SidebarTag";
import SideCategories from "./sidebar/SideCategories";
import Comment from "./Comment";
import { slugify } from "../../utils";
import { Link } from "react-router-dom";
import { FiUser, FiCalendar } from "react-icons/fi";
import { IMAGE_BASE_URL } from "../../lib/api";

const BlogDetailsContent = ({ blogFilter, blogTagArray }) => {
  // console.log("BlogDetailsContent: ", blogFilter);
  return (
    <>
      <div className="post-page-banner rn-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content text-center">
                <div className="page-title">
                  <h1 className="theme-gradient">{blogFilter.blog_title}</h1>
                </div>
                <ul className="rn-meta-list">
                  <li>
                    <FiUser />
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        `/archive/${slugify(blogFilter.blog_author)}`
                      }
                    >
                      {blogFilter.blog_author}
                    </Link>
                  </li>
                  <li>
                    <FiCalendar />
                    {blogFilter.blog_date}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt--60">
              <div className="col-lg-8 thumbnail ">
                <img
                  className="w-100 radius"
                  src={`${IMAGE_BASE_URL + blogFilter.blog_image}`}
                  alt="Blog Images"
                />
              </div>
              <div className="col-lg-4 category-meta">
                <span className="text">Tags:</span>
                <SidebarTag blogTagArray={blogTagArray} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content">
                <div
                  className="blogDetailsContent"
                  dangerouslySetInnerHTML={{
                    __html: blogFilter.blog_description,
                  }}
                />

                {/* <div className="rn-comment-form pt--60">
                                    <div className="comment-respond">
                                        <h4 className="title mb--40">Leave a Reply</h4>
                                        <Comment
                                            url=""
                                            id={blogFilter.id}
                                            title={blogFilter.title}
                                        />
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogDetailsContent;
