import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
import SlipThree from "../elements/split/SlipThree";
import SlipFour from "../elements/split/SlipFour";
import PricingThree from "../elements/pricing/PricingThree";
import ServiceThree from "../elements/service/ServiceThree";
import ServiceTwo from "../elements/service/ServiceTwo";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import ScrollAnimation from "react-animate-on-scroll";
import Mission from "../elements/about/Mission";
import {
  IMAGE_BASE_URL,
  businessApi,
  homeApi,
  testimonialApi,
  faqsApi,
  blogApi,
  howItWorkApi,
  packageApi,
  howItSignupApi,
} from "../lib/api";
import LoadingComponent from "../components/LoadingComponent";
import { calltoactionApi } from "../lib/api";
import homebanner from "../../src/assets/images/home/homebanner.png";
const HomeDefault = () => {
  const [modalShow, setModalShow] = useState(false);
  const [businessApiData, setBusinessApiData] = useState([]);
  const [homeApiData, setHomeApiData] = useState([]);
  const [testimonialApiData, setTestimonialApiData] = useState([]);
  const [faqsApiData, setFaqsApiData] = useState([]);
  const [blogApiData, setBlogApiData] = useState([]);
  const [calltoactionApiData, setCalltoactionApiData] = useState([]);
  const [howItWorkApiData, setHowItWorkApiData] = useState([]);
  const [packageSubscriptionApiData, setPackageSubscriptionApiData] = useState(
    []
  );
  const [packageTrialApiData, setPackageTrialApiData] = useState([]);
  const [howItSignupApiData, setHowItSignupApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  var BlogListData = blogApiData.slice(0, 3);
  // console.log("BlogListData: ", BlogListData);

  const modalHidefun = () => {
    setModalShow(false);
  };
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [
          businessData,
          homeData,
          testimonialData,
          faqsData,
          blogData,
          calltoactionData,
          howItWorkData,
          packageData,
          howItSignupData,
        ] = await Promise.all([
          businessApi(),
          homeApi(),
          testimonialApi(),
          faqsApi(),
          blogApi(),
          calltoactionApi(),
          howItWorkApi(),
          packageApi(),
          howItSignupApi(),
        ]);

        // Updating state with the API data
        setBusinessApiData(businessData[0]);
        setHomeApiData(homeData[0]);
        setTestimonialApiData(testimonialData);
        setFaqsApiData(faqsData);
        setBlogApiData(blogData);
        setCalltoactionApiData(calltoactionData[0]);
        setHowItWorkApiData(howItWorkData);
        setPackageSubscriptionApiData(packageData[0]);
        setPackageTrialApiData(packageData[1]);
        setHowItSignupApiData(howItSignupData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  const handleSignInModal = () => {
    setModalShow(true);
  };

  console.log("howItSignupApiData: ", howItSignupApiData);

  return (
    <>
      <SEO businessApiData={businessApiData} />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
          modalHidefun={() => modalHidefun()}
          modalShowSignIn={modalShow}
        />
        {/* Start Slider Area  */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div
              className="slider-area slider-style-3 height-850 rn-section-gap"
              style={{
                backgroundImage: `url(${homebanner})`,
              }}
            >
              <div className="container">
                <div className="row row--30 align-items-center">
                  <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                    <div className="inner text-start">
                      <h1 className="title theme-gradient">
                        {homeApiData?.banner_title}
                      </h1>
                      <p className="description">
                        {homeApiData?.banner_short_des}
                      </p>
                      <div className="button-group mt--40">
                        <a
                          className="btn-default btn-small btn-icon"
                          href="#"
                          onClick={() => setModalShow(true)}
                        >
                          Sign In{" "}
                          <i className="icon">
                            <FiArrowRight />
                          </i>
                        </a>
                        <a
                          className="btn-default-two btn-small"
                          href="#"
                          onClick={() => setModalShow(true)}
                        >
                          Sign Up{" "}
                          <i className="icon">
                            <FiArrowRight />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="order-1 order-lg-2 col-lg-5">
                    <div className="">
                      <img
                        src={`${IMAGE_BASE_URL + homeApiData?.banner_img}`}
                        alt={homeApiData?.banner_alt_tag}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider Area  */}

            <CalltoActionOne
              title={calltoactionApiData?.title}
              des={calltoactionApiData?.short_dec}
              backgrounImg={calltoactionApiData?.call_to_action_image}
            />
            {/* Start Elements Area  */}
            <div className="rwt-split-area">
              <div className="wrapper container mt--75">
                <SlipThree calltoactionApiData={calltoactionApiData} />
              </div>
            </div>
            {/* End Elements Area  */}
            {/* <Separator /> */}
            {/* Start Elements Area  */}
            <div className="rwt-split-area">
              <div className="wrapper container">
                <SlipFour calltoactionApiData={calltoactionApiData} />
              </div>
            </div>
            {/* End Elements Area  */}

            {/* <Separator /> */}

            {/* Start Elements Area  */}
            <div
              className="rwt-pricing-area rn-section-gap"
              id="pricePlanCustom"
            >
              <div className="container">
                <div className="row mb--35">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle={homeApiData?.price_heading}
                      title={homeApiData?.price_title}
                      description={homeApiData?.price_shorts_des}
                    />
                  </div>
                </div>
                <PricingThree
                  modalHidefun={() => modalHidefun()}
                  modalShowSignIn={modalShow}
                  handleSignInModal={handleSignInModal}
                  packageSubscriptionApiData={packageSubscriptionApiData}
                  packageTrialApiData={packageTrialApiData}
                />
              </div>
            </div>
            {/* End Elements Area  */}

            <Separator />

            {/* Start Service Area  */}
            <div className="rn-service-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      // subtitle="What we can do for you"
                      title={homeApiData?.Works_heading}
                    // description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                    />
                  </div>
                </div>
                <ServiceTwo
                  cardStyle="card-style-1"
                  textAlign="text-start"
                  howItWorkApiData={howItWorkApiData}
                />
              </div>
            </div>
            {/* End Service Area  */}

            <Separator />

            {/* Start Service Area  */}
            <div className="rn-service-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle={homeApiData?.signup_heading}
                      title={homeApiData?.signup_title}
                    // description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                    />
                  </div>
                </div>
                <ServiceThree
                  serviceStyle="service__style--2"
                  textAlign="text-center"
                  howItSignupApiData={howItSignupApiData}
                />
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                  className="button-group text-center"
                >
                  <Link className="btn-default round" to="/price">
                    <span>Start Trading 100% Free</span>
                  </Link>
                  <p>100% FREE TO USE | NO CREDIT CARD REQUIRED</p>
                </ScrollAnimation>
              </div>
            </div>
            {/* End Service Area  */}

            <Separator />

            {/* Start Elements Area  */}
            <div className="rwt-testimonial-area rn-section-gap">
              <div className="container">
                <div className="row mb--20">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle={homeApiData?.testimonial_heading}
                      title={homeApiData?.testimonial_title}
                      description={homeApiData?.testimonial_shorts_des}
                    />
                  </div>
                </div>
                <TestimonialOne
                  testimonialApiData={testimonialApiData}
                  column="col-lg-4 col-md-6 col-12"
                  teamStyle="card-style-default testimonial-style-one style-two"
                />
              </div>
            </div>
            {/* End Elements Area  */}

            <Separator />
            <div className="blog-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle={homeApiData?.blog_heading}
                      title={homeApiData?.blog_title}
                    // description="We provide company and finance service for <br /> startups and company business."
                    />
                  </div>
                </div>
                <div className="row row--15">
                  {BlogListData.slice(0, 4).map(
                    (item) =>
                      // Check if the blog is active before rendering
                      item.isActive === 1 && (
                        <div
                          key={item.id}
                          className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                        >
                          <BlogList
                            StyleVar="box-card-style-default"
                            data={item}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <Separator />

            {/* Start Service Area  */}
            <div className="rn-service-area rn-section-gap ">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      // subtitle="What we can do for you"
                      title={homeApiData?.faq_heading}
                    // description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                    />
                  </div>
                </div>
                <Mission faqsApiData={faqsApiData} />
              </div>
            </div>
            {/* End Service Area  */}
          </>
        )}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
