import React from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../lib/api";

const BlogListTwo = ({ data, StyleVar }) => {
  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link
            to={process.env.PUBLIC_URL + `/blog-details/${data.blog_slug}`}
            className="image"
          >
            <img
              src={`${IMAGE_BASE_URL}/${data.blog_thumbnail}`}
              alt={data.thumbnail_alt_tag}
            />
          </Link>
        </div>
        <div className="content">
          <ul className="rn-meta-list">
            <li>
              <Link
                to={
                  process.env.PUBLIC_URL +
                  `/archive/${slugify(data.blog_author)}`
                }
              >
                {data.blog_author}
              </Link>
            </li>
            <li className="separator">/ {data.blog_date}</li>
            <h4 className="title">
              <Link
                to={process.env.PUBLIC_URL + `/blog-details/${data.blog_slug}`}
              >
                {data.blog_title}
              </Link>
            </h4>
            <li className="modernWay">{data.blog_short_des}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
BlogListTwo.propTypes = {
  data: PropTypes.object,
};
export default BlogListTwo;
