import { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { adsApi } from "../lib/api";
import { IMAGE_BASE_URL } from "../lib/api";
const AdsPopup = () => {
  const [adsApiData, setAdsApiData] = useState([]);
  const [adsmodalShow, setAdsModalShow] = useState(false);
  const handleAllowClick = () => {
    // Set a flag in local storage to indicate that cookies are allowed
    localStorage.setItem("AdsAccepted", "true");
    // Close the modal
    setAdsModalShow(false);
  };

  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      try {
        // Calling businessApi and homeApi simultaneously
        const [adsData] = await Promise.all([adsApi()]);

        // Updating state with the API data
        setAdsApiData(adsData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  // Check if cookies have been accepted before showing the modal
  useEffect(() => {
    // const AdsAccepted = localStorage.getItem("AdsAccepted");

    // if (AdsAccepted === "true") {
    //   setAdsModalShow(false);
    // }
    const currentURL = window.location.pathname;
    const adsFilter = adsApiData?.filter((ele) => ele.isActive === 1);
    console.log("adsFilter: ", adsFilter);
    setTimeout(() => {
      if (currentURL === "/") {
        if (adsFilter.length !== 0) {
          setAdsModalShow(true);
        } else {
          console.log("ads length zero");
        }
      } else {
        console.log("current path is not matched");
      }
    }, 5000);
  }, [adsApiData]);

  return (
    <>
      <Modal
        style={{ padding: "0px" }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={adsmodalShow}
        onHide={() => {
          setAdsModalShow(false);
        }}
      >
        <Modal.Body style={{ backgroundColor: "gray" }}>
          <div>
            {adsApiData?.map((ele, index) => {
              return (
                ele.isActive === 1 && (
                  <a href={ele.ads_url} key={index}>
                    <img
                      src={IMAGE_BASE_URL + ele.ads_image}
                      alt={ele.alt_tag}
                    />
                  </a>
                )
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdsPopup;
