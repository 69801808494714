import React, { useState, useEffect } from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import LoadingComponent from "../components/LoadingComponent";
import { websiteSettingApi, allHeadingApi, IMAGE_BASE_URL } from "../lib/api";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [websiteSettingApiData, setWebsiteSettingApiData] = useState([]);
  const [allHeadingApiData, setAllHeadingApiData] = useState([]);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [websiteSettingData, allHeadingData] = await Promise.all([
          websiteSettingApi(),
          allHeadingApi(),
        ]);
        // Updating state with the API data
        setWebsiteSettingApiData(websiteSettingData[0]);
        setAllHeadingApiData(allHeadingData[0]);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* <SEO businessApiData={businessApiData} /> */}
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        {/* Start Slider area  */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div
              className="slider-area slider-style-2 variation-2  height-750 bg_image"
              style={{
                backgroundImage: `url(${
                  IMAGE_BASE_URL + allHeadingApiData?.policy_banner
                }`,
                height: "50vh",
              }}
            ></div>
            {/* End Slider area  */}
            <div className="container mt--75 mb--75">
              <h3 className="text-center">Privacy Policy</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: websiteSettingApiData?.privacy_policy,
                }}
              ></div>
            </div>
          </>
        )}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default PrivacyPolicy;
