import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { IMAGE_BASE_URL } from '../../lib/api';


var settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    responsive: [{
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
        }
    },
    {
        breakpoint: 993,
        settings: {
            slidesToShow: 3,
        }
    },
    {
        breakpoint: 580,
        settings: {
            slidesToShow: 2,
        }
    },
    {
        breakpoint: 481,
        settings: {
            slidesToShow: 1,
        }
    }
    ]
};



// const testimonialData = [
//     {
//         image: 'testimonial-01',
//         name: 'Sr Janen Sara',
//         designation: 'Sr Product Designer',
//         location: 'CO Miego, AD, USA',
//         description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',

//     },
//     {
//         image: 'testimonial-02',
//         name: 'Afsana Nila',
//         designation: 'App Developer',
//         location: 'Bangladesh',
//         description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',

//     },
//     {
//         image: 'testimonial-03',
//         name: 'Afanan Sifa',
//         designation: 'Accounts Manager',
//         location: 'Poland',
//         description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
//     },

// ]


const TestimonialOne = ({ column, teamStyle, testimonialApiData }) => {
    return (
        <div className="row row--15">
            <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...settings}>
                {testimonialApiData && testimonialApiData.map((data, index) => (
                    <div className={`${column}`} key={index}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <div className={`rn-box-card ${teamStyle}`}>
                                <div className="inner">
                                    <figure className="thumbnail">
                                        <img src={IMAGE_BASE_URL + data?.testimonial_image} alt={data?.alt_tag} />
                                    </figure>
                                    <figcaption className="content">
                                        <p className="description">{data?.testimonial}</p>
                                        <h2 className="title">{data.name}</h2>
                                        {/* <h6 className="subtitle theme-gradient">{data.designation}</h6> */}
                                    </figcaption>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default TestimonialOne;
