import React, { useState, useEffect } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import Separator from "../elements/separator/Separator";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import PricingThree from "../elements/pricing/PricingThree";
import SectionTitleTwo from "../elements/sectionTitle/SectionTitleTwo";
import { packageApi, homeApi } from "../lib/api";
import LoadingComponent from "../components/LoadingComponent";
const PricePage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [homeApiData, setHomeApiData] = useState([]);
  const [packageSubscriptionApiData, setPackageSubscriptionApiData] = useState(
    []
  );
  const [packageTrialApiData, setPackageTrialApiData] = useState([]);

  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [packageData, homeData] = await Promise.all([
          packageApi(),
          homeApi(),
        ]);
        setPackageSubscriptionApiData(packageData[0]);
        setPackageTrialApiData(packageData[1]);
        setHomeApiData(homeData[0]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  const handleSignInModal = () => {
    setModalShow(true);
  };
  const modalHidefun = () => {
    setModalShow(false);
  };

  return (
    <>
      <SEO title="Price" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
          modalHidefun={() => modalHidefun()}
          modalShowSignIn={modalShow}
        />

        <Separator />

        {/* Start Elements Area  */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <div
            className="rwt-pricing-area rn-section-gap mt--35"
            id="pricePlanCustom"
          >
            <div className="container">
              <div className="row mb--35">
                <div className="col-lg-12">
                  <SectionTitleTwo
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle={homeApiData?.price_heading}
                    title={homeApiData?.price_title}
                    description={homeApiData?.price_shorts_des}
                  />
                </div>
              </div>
              <PricingThree
                modalHidefun={() => modalHidefun()}
                modalShowSignIn={modalShow}
                handleSignInModal={handleSignInModal}
                packageSubscriptionApiData={packageSubscriptionApiData}
                packageTrialApiData={packageTrialApiData}
              />
            </div>
          </div>
        )}

        {/* End Elements Area  */}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default PricePage;
