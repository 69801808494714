import React, { useState, useEffect } from "react";
import { FiChevronUp } from "react-icons/fi";
import Blury from "../Blury";
import whatsappIcon from "../../assets/images/whatsapp.png"
import { businessApi } from "../../lib/api";
const WhatsappComponent = () => {
    const [visible, setVisible] = useState(false);

    const [businessApiData, setBusinessApiData] = useState([]);
    useEffect(() => {
        // Function to fetch data from both APIs
        const fetchData = async () => {
            try {
                // Calling businessApi and homeApi simultaneously
                const [businessData] = await Promise.all([businessApi()]);

                // Updating state with the API data
                setBusinessApiData(businessData[0]);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, []);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    window.addEventListener("scroll", toggleVisible);

    return (
        <>
            <div
                className="rn-back-top-whatsapp"
            // onClick={scrollToTop}
            // style={{ display: visible ? "inline" : "none" }}
            >
                <a
                    href={`https://wa.me/${businessApiData?.whatsapp_phone
                        }?text=${encodeURIComponent("")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img style={{ width: "50px", height: "50px" }} src={whatsappIcon} alt="whatsappIcon" />
                </a>

            </div>
            <Blury />
        </>
    );
};

export default WhatsappComponent;
