import React, { useState, useEffect } from "react";
import ContactForm from "./ContactForm";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { businessApi } from "../../lib/api";
import SupportForm from "./SupportForm";
import { useSelector } from "react-redux";

const ContactOne = () => {
  const loginAuth = useSelector((state) => state.loginAuth);
  const [businessApiData, setBusinessApiData] = useState([]);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      try {
        // Calling businessApi and homeApi simultaneously
        const [businessData] = await Promise.all([businessApi()]);

        // Updating state with the API data
        setBusinessApiData(businessData[0]);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact Phone Number</h4>
                    <p>
                      <a href={`tel:${businessApiData?.phone}`}>
                        {businessApiData?.phone} (Mobile No)
                      </a>
                    </p>
                    <p>
                      <a
                        href={`https://wa.me/${businessApiData?.whatsapp_phone
                          }?text=${encodeURIComponent("")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {businessApiData?.whatsapp_phone} (Whatsapp)
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Email Address</h4>
                    <p>
                      <a href={`mailto:${businessApiData?.email}`}>
                        {businessApiData?.email}
                      </a>
                    </p>
                    {/* <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Location</h4>
                    <p>{businessApiData?.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-6">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-6 mt_md--30 mt_sm--30">
          {/* <GoogleMapStyle /> */}
          {loginAuth ? <SupportForm formStyle="contact-form-1" /> : null}
        </div>
      </div>
    </>
  );
};
export default ContactOne;
