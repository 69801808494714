import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import axios from "axios";
// Pages import Here
import HomeDefault from "./pages/HomeDefault";
import ForgetPasswrd from "./pages/ForgetPasswrd";

import AboutUs from "./pages/AboutUs";
import Refer from "./pages/Refer";

import Contact from "./elements/contact/Contact";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";
import PricePage from "./pages/PricePage";
import Signal from "./pages/Signal";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandConditions from "./pages/TermsandConditions";
import ReturnAndRefundPolicy from "./pages/ReturnAndRefundPolicy";
import CookiesPolicy from "./pages/CookiesPolicy";
import AffiliatePolicy from "./pages/AffiliatePolicy";
import Profile from "./pages/Profile";
import Faq from "./pages/Faq";
import ChangePassword from "./pages/ChangePassword";
import LoadingComponent from "./components/LoadingComponent";
// Import Css Here
import "./assets/scss/style.scss";
import { useDispatch, useSelector } from "react-redux";
import { userAuth } from "./redux/action/loginAuth";
import Checkout from "./pages/Checkout";
import { BASE_URL } from "./lib/api";
const App = () => {
  const dispatch = useDispatch();
  const loginAuth = useSelector((state) => state.loginAuth);
  // get user data if user is authenticated
  // start
  useEffect(() => {
    const userToken = localStorage.getItem("usertoken");
    // Axios request configuration
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: `${BASE_URL}/forex/user/loggeduser`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    // Make the API request using Axios
    axios(axiosConfig)
      .then((res) => {
        // Handle the response
        // setApiResponse(response.data);
        dispatch(
          userAuth({
            loginAuth: true,
            token: userToken,
            userData: res.data.user,
          })
        );
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }, []);
  // end
  return (
    <BrowserRouter>
      <PageScrollTop>
        <Routes>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <HomeDefault />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/price"}`}
            exact
            element={<PricePage />}
          />
          {loginAuth ? (
            <Route
              path={`${process.env.PUBLIC_URL}/signal`}
              exact
              element={<Signal />}
            />
          ) : null}
          {loginAuth ? (
            <Route
              path={`${process.env.PUBLIC_URL + "/refer"}`}
              exact
              element={<Refer />}
            />
          ) : null}
          <Route
            path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
            exact
            element={<PrivacyPolicy />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/terms-and-conditions"}`}
            exact
            element={<TermsandConditions />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/return-and-refund-policy"}`}
            exact
            element={<ReturnAndRefundPolicy />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/cookies-policy"}`}
            exact
            element={<CookiesPolicy />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/affiliate-policy"}`}
            exact
            element={<AffiliatePolicy />}
          />
          {loginAuth ? (
            <Route
              path={`${process.env.PUBLIC_URL + "/profile"}`}
              exact
              element={<Profile />}
            />
          ) : null}
          <Route
            path={`${process.env.PUBLIC_URL + "/faq"}`}
            exact
            element={<Faq />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/forget-passwrd"}`}
            exact
            element={<ForgetPasswrd />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/change-password"}`}
            exact
            element={<ChangePassword />}
          />
          {/* Blog Part  */}
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            exact
            element={<Blog />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-details/:blog_slug"}`}
            exact
            element={<BlogDetails />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            element={<Contact />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/checkout"}`}
            exact
            element={<Checkout />}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about-us"}`}
            exact
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route path="*" exact element={<Error />} />
        </Routes>
      </PageScrollTop>
    </BrowserRouter>
  );
};

export default App;
