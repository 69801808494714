import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FaPlus } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 25
            }}
            onClick={decoratedOnClick}
        >
            <div style={{ color: "#ACACAC" }}>
                {children}
            </div>
            <div style={{ cursor: "pointer" }}>
                <FaPlus color='#ACACAC' />
            </div>
        </div>
    );
}

const AccordionOne = ({ customStyle, faqsApiData }) => {
    const location = useLocation();
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            {
                faqsApiData && faqsApiData.slice(0, location.pathname === "/faq" ? faqsApiData?.length : 3).map((ele, index) => {
                    return (
                        <Card key={index}>
                            <Card.Header>
                                {/* <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        What is Doob ? How does it work?
                    </Accordion.Toggle> */}
                                <CustomToggle eventKey={index}>{ele.question}</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body dangerouslySetInnerHTML={{ __html: ele.answer }}></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })
            }
        </Accordion>
    )
}
export default AccordionOne

