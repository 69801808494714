import React, { useState, useEffect } from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import Separator from "../elements/separator/Separator";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import LoadingComponent from "../components/LoadingComponent";
import { faqsApi, homeApi } from "../lib/api";
import Mission from "../elements/about/Mission";
import { useLocation } from "react-router-dom";
const Faq = () => {
  const [homeApiData, setHomeApiData] = useState([]);
  const [faqsApiData, setFaqsApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [faqsData, homeData] = await Promise.all([faqsApi(), homeApi()]);
        setFaqsApiData(faqsData);
        setHomeApiData(homeData[0]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <SEO title="Faq" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        <Separator />
        <BreadcrumbOne
          title={homeApiData?.faq_heading}
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Faq"
        />
        {/* Start Service Area  */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className="rn-service-area rn-section-gap ">
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    // subtitle="What we can do for you"
                    title={homeApiData?.faq_heading}
                    // description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                  />
                </div>
              </div>
              <Mission faqsApiData={faqsApiData} />
            </div>
          </div>
        )}
        {/* End Service Area  */}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Faq;
