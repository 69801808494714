import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { IMAGE_BASE_URL } from "../../lib/api";
const callToActionData = {
  title: "Everything You Need to <br/> Become a Profitable Trader",
  subtitle:
    "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. They live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.",
  btnText: "Purchase Doob",
};

const CalltoActionCustom = ({ aboutApiData }) => {
  return (
    <div
      className="rn-callto-action rn-call-to-action style-8 content-wrapper"
      style={{
        backgroundImage: `url(${IMAGE_BASE_URL + aboutApiData?.cta_img})`,
      }}
    >
      <div className="container">
        <div className="row row--0 align-items-center ">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <h2
                  className="title"
                  style={{ color: "#07E5B5" }}
                  dangerouslySetInnerHTML={{ __html: aboutApiData?.cta_title }}
                ></h2>
                <p>{aboutApiData?.cta_description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionCustom;
