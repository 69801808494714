import React, { useState, useEffect, useCallback } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import { packageApi, homeApi, razorpayApi, businessApi } from "../lib/api";
import LoadingComponent from "../components/LoadingComponent";
import profileImg from "../assets/images/profileImg.png";
import { useDispatch, useSelector } from "react-redux";
import { FiCheck } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { LuMinus } from "react-icons/lu";
import useRazorpay from "react-razorpay";
import { IMAGE_BASE_URL, BASE_URL } from "../lib/api";
import checkoutBg from "../assets/images/home/checkoutBg.jpg";
import swal from "sweetalert";
import axios from "axios";
import moment from "moment";
import { userAuth } from "../redux/action/loginAuth";
const Checkout = () => {
  const dispatch = useDispatch
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData);
  const [businessApiData, setBusinessApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [homeApiData, setHomeApiData] = useState([]);
  const [packageSubscriptionApiData, setPackageSubscriptionApiData] = useState(
    []
  );
  const [razorpayData, setRazorpayData] = useState([]);
  const [razerpayKey, setRazorpayKey] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [packageData, businessData, razorpayData] = await Promise.all([
          packageApi(),
          businessApi(),
          razorpayApi(),
        ]);

        setPackageSubscriptionApiData(packageData[0]);
        setBusinessApiData(businessData[0]);
        setRazorpayData(razorpayData[0]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching data:", err);
      }

      const date = moment().format("DD-MM-YYYY");
      setCurrentDate(date);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setRazorpayKey(razorpayData.secret_key);
  }, [razorpayData]);

  // console.log("userData: ", userData);

  const [months, setMonths] = useState(1);

  const increment = () => {
    setMonths(months + 1);
  };

  const decrement = () => {
    if (months > 1) {
      setMonths(months - 1);
    }
  };
  const callBackFunction = () => {
    const userToken = localStorage.getItem("usertoken");
    // Axios request configuration
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: `${BASE_URL}/forex/user/loggeduser`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    // Make the API request using Axios
    axios(axiosConfig)
      .then((res) => {
        // Handle the response
        // setApiResponse(response.data);
        dispatch(
          userAuth({
            loginAuth: true,
            token: userToken,
            userData: res.data.user,
          })
        );
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const SubscriptionApiCall = (razorpay_payment_id, c_date = currentDate, p_amount = packageSubscriptionApiData?.package_price * months) => {
    const userToken = localStorage.getItem("usertoken");
    // console.log("userToken: ", userToken);
    // console.log("SubscriptionApiCall: ", {
    //   payment_id: razorpay_payment_id,
    //   user_id: userData.id,
    //   subscribed_started_date: c_date,
    //   subcription_month: months,
    //   package_amount: p_amount,
    // });
    const axiosConfig = {
      method: "post", // Use 'post' if you are making a POST request
      url: `${BASE_URL}/forex/user/subscription`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
      data: {
        payment_id: razorpay_payment_id,
        user_id: userData.id,
        subscribed_started_date: c_date,
        subcription_month: months,
        package_amount: p_amount,
      },
    };
    axios(axiosConfig)
      .then((res) => {
        console.log("Subscription payment data: ", res.data);
        callBackFunction()
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });

    swal("Oops", "Please fill all the field", "error");
  };

  const [Razorpay, isLoaded] = useRazorpay();

  // console.log("razerpayKey: ", razerpayKey);
  const handlePayment = useCallback(
    async (razerpayKeyId, amount) => {
      const options = {
        // key: "rzp_test_IrOcLSktfeMzaZ",
        // amount: 100,
        key: razerpayKeyId,
        amount: amount,
        currency: "INR",
        name: "Forexsupersignal.com",
        description: "Test Transaction",
        image:
          "https://cms.forexsupersignal.com/upload/business/65c7893765fbc.png",
        //   order_id: "65f4d65f4g64g",
        handler: async (res) => {
          console.log(res);
          if (res.razorpay_payment_id) {
            await SubscriptionApiCall(res.razorpay_payment_id);
            swal(
              "Payment successfull",
              "Your payment is successfull!",
              "success"
            );
            // navigate("/signal");
          } else {
            swal("Failed", "Your payment is failed", "error");
          }
        },
        prefill: {
          name: userData?.name,
          email: userData?.email,
          contact: userData?.mobile,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );

  return (
    <>
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        {/* Start Slider Area  */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div>
              <div
                className="slider-area slider-style-2 variation-2  height-750 bg_image"
                style={{
                  backgroundImage: `url(${checkoutBg}`,
                  height: "30vh",
                  objectFit: "fill",
                  backgroundPosition: "center",
                }}
              />
              <div className="container mt--200 mb--100 ">
                <div
                  className="row pb--100"
                  style={{
                    backgroundColor: "#1e1e1e",
                  }}
                >
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="rn-pricing style-2 active">
                      <div className="pricing-table-inner">
                        <div className="pricing-header">
                          <h4 className="title">
                            {/* {packageSubscriptionApiData?.package_name} */}
                            Packge Details
                          </h4>
                          <div className="pricing">
                            <div className="price-wrapper">
                              <span className="currency">₹</span>
                              <span className="price">
                                {packageSubscriptionApiData?.package_price}
                              </span>
                            </div>
                            <span className="subtitle">
                              {packageSubscriptionApiData?.package_days}
                            </span>
                          </div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list-style--1">
                            {packageSubscriptionApiData.feature?.map((ele) => {
                              return (
                                <li>
                                  <FiCheck />
                                  {ele?.package_feature}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <ul class="list-group mb-3">
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0 text-muted">MONTH</h6>
                          {/* <small class="text-muted">Brief description</small> */}
                        </div>
                        <span class="text-muted">x {months}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0 text-muted">Add Subscription</h6>
                          {/* <small class="text-muted">Brief description</small> */}
                        </div>
                        <div className="d-flex align-items-center">
                          <button
                            onClick={decrement}
                            className=""
                            style={{
                              //   border: "1px solid #CCC",
                              padding: "4px 5px",
                              marginRight: "15px",
                              backgroundColor: "rgb(10, 220, 156)",
                              border: "none",
                            }}
                          >
                            <LuMinus color="#FFF" />
                          </button>
                          <div
                            className=""
                            style={{
                              marginRight: "15px",
                            }}
                          >
                            <span>{months}</span>
                          </div>
                          <button
                            onClick={increment}
                            style={{
                              border: "none",
                              padding: "4px 5px",
                              marginRight: "15px",
                              backgroundColor: "rgb(10, 220, 156)",
                            }}
                          >
                            <FiPlus color="#FFF" />
                          </button>
                        </div>
                      </li>
                      {/* <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0 text-muted">Third item</h6>
                          <small class="text-muted">Brief description</small>
                        </div>
                        <span class="text-muted">$5</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between bg-light">
                        <div class="text-success">
                          <h6 class="my-0 text-muted">Promo code</h6>
                          <small>EXAMPLECODE</small>
                        </div>
                        <span class="text-success">-$5</span>
                      </li> */}
                      <li class="list-group-item d-flex justify-content-between">
                        <span>Total (INR)</span>
                        <strong>
                          ₹{packageSubscriptionApiData?.package_price * months}
                        </strong>
                      </li>
                      <div className="form-group">
                        <button
                          onClick={() =>
                            handlePayment(
                              razerpayKey,
                              100 *
                              packageSubscriptionApiData.package_price *
                              months
                            )
                          }
                          className="btn-default-two btn-small"
                          style={{
                            width: "100%",
                            height: "44px",
                            lineHeight: "40px",
                          }}
                        >
                          {loading ? "Submitting..." : "PAY NOW"}
                        </button>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Checkout;
