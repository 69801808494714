import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { IMAGE_BASE_URL } from "../../lib/api";
import { Link } from "react-router-dom";

const callToActionData = {
  title:
    "Navigating the Financial Markets: A Guide to Trading Strategies and Tactics",
  subtitle:
    "Explore the dynamic world of trading with our comprehensive guide. Learn effective trading strategies, understand market trends, and master the art of risk management. Whether you're a novice or experienced trader, discover key insights that can enhance your decision-making and propel you towards financial success in the fast-paced realm of trading.",
  btnText: "Contact Us",
};

const CalltoActionOne = ({ title, des, backgrounImg }) => {
  return (
    <div
      className="rn-callto-action clltoaction-style-default bg_image bg_image_fixed"
      style={{ backgroundImage: `url(${IMAGE_BASE_URL}/${backgrounImg})` }}
      data-black-overlay="7"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h2 className="title">{title}</h2>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h5 className="">{des}</h5>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="call-to-btn">
                    <Link className="btn-default" to="/contact">
                      {callToActionData.btnText}
                    </Link>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionOne;
