import loadingAnimation from "../assets/lottie/animation_loading.json";
import Lottie from "lottie-react";
import { useLottie } from "lottie-react";

const LoadingComponent = () => {
    const options = {
        animationData: loadingAnimation,
        loop: true,
    };

    const { View } = useLottie(options);
    return (
        <div style={{ textAlign: "center", height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <div style={{ width: "200px", height: "200px", }}>
                {View}
            </div>
        </div>
    )
}

export default LoadingComponent;