import { useState, useRef, useEffect } from "react";
import { FiMenu, FiKey, FiEye, FiEyeOff } from "react-icons/fi";

import Logo from "../../elements/logo/Logo";
import loginBg from "../../assets/images/loginBg.png";
import registerBg from "../../assets/images/registerBg.png";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import Modal from "react-bootstrap/Modal";
import HeaderTopNews from "./HeaderTopNews";
import Select from "react-select";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../lib/api";
import swal from "sweetalert";
import LoadingComponent from "../../components/LoadingComponent";
import { userAuth } from "../../redux/action/loginAuth";
import { TiTick } from "react-icons/ti";
import CookiesPopup from "../../components/CookiesPopup";
import AdsPopup from "../../components/AdsPopup";
const HeaderOne = ({
  btnStyle,
  HeaderSTyle,
  modalShowSignIn,
  modalHidefun,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loginAuth = useSelector((state) => state.loginAuth);
  const userData = useSelector((state) => state.userData);

  const [modalShow, setModalShow] = useState(false);
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const [passwordShowToggle, setpasswordShowToggle] = useState(true)
  useEffect(() => {
    setModalShow(modalShowSignIn);
  }, [modalShowSignIn]);

  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;

  const checkChange = (value) => {
    setCheck(value);
  };

  const toggleForm = () => {
    const container = document.querySelector(".login-register");
    container.classList.toggle("active");
  };
  const handleSignInModal = async () => {
    setModalShow(true);
  };

  const modalHiideFunc = async () => {
    const currentURL = window.location.pathname;
    if (currentURL === "/" || currentURL === "/price") {
      modalHidefun();
    } else {
      console.log("current path is not matched");
    }
  };

  // console.log("userData: ", userData);

  // start country & state selection
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [referalId, setReferalId] = useState("");

  useEffect(() => {
    axios
      .get("https://api.countrystatecity.in/v1/countries", {
        headers: {
          "X-CSCAPI-KEY":
            "YzhZUDcxZmdSQVNWUjNNdkhUbFVLdHB3ZTNnYjNqZTZKYnJ2dlBYUw==", // Replace with your actual API key
        },
      })
      .then((response) => {
        const countryOptions = response.data.map((country) => ({
          value: country.iso2,
          label: country.name,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${selectedCountry.value}/states`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "YzhZUDcxZmdSQVNWUjNNdkhUbFVLdHB3ZTNnYjNqZTZKYnJ2dlBYUw==", // Replace with your actual API key
            },
          }
        )
        .then((response) => {
          const stateOptions = response.data.map((state) => ({
            value: state.iso2,
            label: state.name,
          }));
          setStates(stateOptions);
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
          setStates([]); // Reset states on error
        });
    } else {
      setStates([]); // Reset states when no country is selected
    }
  }, [selectedCountry]);

  // end country & state selection

  // start auto password generator function

  const [password, setPassword] = useState("");

  const generatePassword = () => {
    const length = 12; // Length of the password
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let retVal = "";

    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    setPassword(retVal.toUpperCase());
  };

  // end auto password generator function

  // start handleRegister
  const handleRegister = (event) => {
    event.preventDefault();
    if (
      name !== "" &&
      email !== "" &&
      mobileNo !== "" &&
      selectedCountry !== null &&
      selectedState !== null &&
      password !== ""
    ) {
      setLoading(true);
      axios
        .post(`${BASE_URL}/forex/user/register`, {
          name: name,
          user_country: selectedCountry.label,
          user_state: selectedState.label,
          email: email,
          mobile: mobileNo,
          whatsapp_num: whatsappNo,
          password: password,
          password_confirmation: password,
          referral_id: referalId,
        })
        .then((res) => {
          console.log("res: ", res);
          setLoading(false);
          swal(
            "Register",
            "Your account has been successfully created!",
            "success"
          );
          setName("");
          setEmail("");
          setMobileNo("");
          setWhatsappNo("");
          setCountries("");
          setSelectedCountry("");
          setStates("");
          setSelectedState("");
          setReferalId("");
          setPassword("");
          setModalShow(false);
        })
        .catch((err) => {
          setLoading(false);
          swal("Oops", "Please try again", "error");
          console.log("err: ", err);
        });
    } else {
      swal("Oops", "Please fill all the fields", "error");
    }
  };
  // end handleRegister

  const callBackFunction = () => {
    const userToken = localStorage.getItem("usertoken");
    // Axios request configuration
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: "https://cms.forexsupersignal.com/api/forex/user/loggeduser",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    // Make the API request using Axios
    axios(axiosConfig)
      .then((res) => {
        // Handle the response
        dispatch(
          userAuth({
            loginAuth: true,
            token: userToken,
            userData: res.data.user,
          })
        );
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };

  // start handleLogin
  const handleLogin = async (event) => {
    const userToken = localStorage.getItem("usertoken");
    event.preventDefault();
    if (loginEmail !== "" && loginPassword !== "") {
      setLoading(true);
      axios
        .post(`${BASE_URL}/forex/user/login`, {
          email: loginEmail,
          password: loginPassword,
        })
        .then((res) => {
          console.log("res: ", res);
          setLoading(false);
          swal("Login", "Login successfully !", "success");
          // console.log("res.data.token: ", res.data.token);
          localStorage.setItem("usertoken", res.data.token);
          dispatch(
            userAuth({
              loginAuth: true,
              token: res.data.token,
            })
          );
          callBackFunction();
          setModalShow(false);
        })
        .catch((err) => {
          setLoading(false);
          swal("Oops", "Please try again", "error");
          console.log("err: ", err);
        });
    } else {
      swal("Oops", "Please enter correct username & password", "error");
    }
  };
  // end handleLogin

  const [isChecked, setChecked] = useState(false);
  const CustomCheckbox = () => {
    const handleCheckboxChange = () => {
      setChecked(!isChecked);
    };

    return (
      <div
        style={{
          width: "15px",
          height: "15px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          background: isChecked ? "#0ADC9C" : "white",
          color: isChecked ? "white" : "black",
          marginRight: "10px",
        }}
        onClick={handleCheckboxChange}
      >
        {isChecked && <TiTick size={16} />}
      </div>
    );
  };

  // console.log("isChecked: ", isChecked);

  const passwordShowHandle = () => {
    setpasswordShowToggle(!passwordShowToggle)
  }

  return (
    <>
      <HeaderTopNews handleSignInModal={() => handleSignInModal()} />
      <CookiesPopup />
      <AdsPopup />
      <header
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4">
              <Logo />
              {/* <h5>Forexsupersignal.com</h5> */}
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav handleSignInModal={() => handleSignInModal()} />
                </nav>
                {loginAuth ? (
                  <div className="header-btn">
                    <Link className={` ${btnStyle}`} to="/profile">
                      Welcome{" "}
                      <span style={{ color: "#0ADC9C" }}>{userData?.name}</span>
                    </Link>
                  </div>
                ) : null}
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
                {/* <Darkmode /> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          modalHiideFunc();
          setModalShow(false);
        }}
      >
        <Modal.Body style={{ background: "#FFF" }}>
          <section className="login-register-form-section">
            <div className="container login-register">
              <div className="user signinBx">
                <div className="imgBx">
                  <img src={loginBg} alt="loginBg" />
                </div>
                <div className="formBx">
                  <form action="">
                    <h2>Sign In</h2>
                    <input
                      type="text"
                      name=""
                      placeholder="Email"
                      autoComplete="off"
                      onChange={(e) => setLoginEmail(e.target.value)}
                      value={loginEmail}
                    />

                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {
                        passwordShowToggle ?
                          <FiEye
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "30%",
                              fontSize: "30px",
                              cursor: "pointer",
                              zIndex: "999",
                              borderLeft: "1px solid #e7e2e2",
                              paddingLeft: "10px",
                            }}
                            onClick={passwordShowHandle}
                          /> :
                          <FiEyeOff
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "30%",
                              fontSize: "30px",
                              cursor: "pointer",
                              zIndex: "999",
                              borderLeft: "1px solid #e7e2e2",
                              paddingLeft: "10px",
                            }}
                            onClick={passwordShowHandle}
                          />
                      }
                      <input
                        type={passwordShowToggle ? "password" : "text"}
                        name=""
                        placeholder="Password"
                        autoComplete="off"
                        onChange={(e) => setLoginPassword(e.target.value)}
                        value={loginPassword}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="btn-default btn-small"
                        onClick={(e) => handleLogin(e)}
                      >
                        Login
                      </button>
                      <p className="signup">
                        <Link to="/forget-passwrd">Forgot password ?</Link>
                      </p>
                    </div>
                    <p className="signup">
                      Don't have an account ?
                      <a href="#" onClick={() => toggleForm()}>
                        Sign Up.
                      </a>
                    </p>
                  </form>
                </div>
              </div>
              <div className="user signupBx">
                <div className="formBx">
                  <form>
                    <h2>Create an account</h2>
                    <input
                      type="text"
                      name=""
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <div>
                      <Select
                        className="mb--10 border-0 bg-red"
                        options={countries}
                        onChange={setSelectedCountry}
                        placeholder="Select a country"
                        isSearchable
                        value={selectedCountry}
                      />
                      <Select
                        options={states}
                        onChange={setSelectedState}
                        placeholder="Select a state"
                        isSearchable
                        value={selectedState}
                        isDisabled={!selectedCountry}
                      />
                    </div>
                    <input
                      type="text"
                      name=""
                      placeholder="Mobile No."
                      autoComplete="off"
                      onChange={(e) => setMobileNo(e.target.value)}
                      value={mobileNo}
                    />
                    <input
                      type="text"
                      name=""
                      placeholder="Whatsapp No."
                      autoComplete="off"
                      onChange={(e) => setWhatsappNo(e.target.value)}
                      value={whatsappNo}
                    />
                    <input
                      type="email"
                      name=""
                      placeholder="Email Address"
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FiKey
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "30%",
                          fontSize: "30px",
                          cursor: "pointer",
                          zIndex: "999",
                          borderLeft: "1px solid #e7e2e2",
                          paddingLeft: "10px",
                        }}
                        onClick={generatePassword}
                      />
                      <input
                        type="text"
                        placeholder="Create Password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <input
                      type="text"
                      name=""
                      placeholder="Referal ID (Optional)"
                      autoComplete="off"
                      onChange={(e) => setReferalId(e.target.value)}
                      value={referalId}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "8px 0px",
                      }}
                    >
                      <CustomCheckbox />
                      <label style={{ fontSize: "14px" }}>
                        I agree to the terms and conditions
                      </label>
                    </div>
                    <button
                      className={`${isChecked ? "btn-default" : "btn-disabled"
                        }  btn-small`}
                      onClick={(e) => handleRegister(e)}
                      disabled={isChecked ? false : true}
                    >
                      Sign Up
                    </button>
                    <p className="signup">
                      Already have an account ?
                      <a href="#" onClick={() => toggleForm()}>
                        Sign in.
                      </a>
                    </p>
                  </form>
                </div>
                <div className="imgBx">
                  <img src={registerBg} alt="registerBg" />
                </div>
                {/* Start loading */}
                {loading && (
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      zIndex: "99999",
                      top: "0",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "10px",
                    }}
                  >
                    <LoadingComponent />
                  </div>
                )}
                {/* end loading */}
              </div>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer> */}
      </Modal>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
