import React, { useEffect, } from 'react';
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import { FiX } from "react-icons/fi";

const MobileMenu = ({ show, onClose }) => {

    useEffect(() => {
        const handleDropdownClick = (event) => {
            const parentElement = event.currentTarget.parentElement;
            const submenu = parentElement.querySelector('.submenu');
            if (submenu) {
                submenu.classList.toggle("active");
                event.currentTarget.classList.toggle("open");
            }
        };

        const handleMegamenuClick = (event) => {
            const parentElement = event.currentTarget.parentElement;
            const megamenu = parentElement.querySelector('.rn-megamenu');
            if (megamenu) {
                megamenu.classList.toggle("active");
                event.currentTarget.classList.toggle("open");
            }
        };

        var elements = document.querySelectorAll('.popup-mobile-menu .has-droupdown > a');
        var elementsTwo = document.querySelectorAll('.popup-mobile-menu .with-megamenu > a');

        elements.forEach(element => {
            element.addEventListener('click', handleDropdownClick);
        });

        elementsTwo.forEach(element => {
            element.addEventListener('click', handleMegamenuClick);
        });

        return () => {
            // Cleanup event listeners when component unmounts
            elements.forEach(element => {
                element.removeEventListener('click', handleDropdownClick);
            });

            elementsTwo.forEach(element => {
                element.removeEventListener('click', handleMegamenuClick);
            });
        };
    }, []); // Empty dependency array ensures the effect runs only once after initial render

    return (
        <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
            <div className="inner">
                <div className="header-top">
                    <Logo
                    />
                    {/* <h5>Forexsupersignal.com</h5> */}
                    <div className="close-menu">
                        <span className="close-button" onClick={onClose}><FiX /></span>
                    </div>
                </div>
                <Nav />
            </div>
        </div>
    );
};

export default MobileMenu;
