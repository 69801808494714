import React, { useState, useEffect, useRef } from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import { businessApi, aboutApi } from "../lib/api";
import LoadingComponent from "../components/LoadingComponent";
import profileImg from "../assets/images/profileImg.png";
import { FiKey } from "react-icons/fi";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { useSelector } from "react-redux";
import profileBg from "../assets/images/home/profileBg.png";
import { Link } from "react-router-dom";
import checkoutBg from "../assets/images/home/checkoutBg.jpg";

const Profile = () => {
  const userData = useSelector((state) => state.userData);
  const [businessApiData, setBusinessApiData] = useState([]);
  const [aboutApiData, setAboutApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [businessData, abutData] = await Promise.all([
          businessApi(),
          aboutApi(),
        ]);

        // Updating state with the API data
        setBusinessApiData(businessData[0]);
        setAboutApiData(abutData[0]);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // ===============================

  const [editing, setEditing] = useState({
    name: false,
    email: false,
    phone: false,
    whatsapp: false,
    password: false,
  });

  // State to hold input field values
  console.log("userData: ", userData);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    whatsapp: "",
    password: "",
  });

  useEffect(() => {
    setFormData({
      name: userData?.name,
      email: userData?.email,
      phone: userData?.mobile,
      whatsapp: userData?.whatsapp_num,
      password: "",
    });
  }, [userData]);

  // console.log("formData: ", formData);

  const [borrderColor, setBorderColor] = useState({
    name: false,
    email: false,
    phone: false,
    whatsapp: false,
    password: false,
  });

  const handleEditClick = (field) => {
    console.log(`Clicked on ${field} edit icon`);
    setEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));

    setBorderColor((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <SEO businessApiData={businessApiData} />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        {/* Start Slider Area  */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div>
              <div
                className="slider-area slider-style-2 variation-2  height-750 bg_image"
                style={{
                  backgroundImage: `url(${checkoutBg}`,
                  height: "30vh",
                  objectFit: "fill",
                  backgroundPosition: "center",
                }}
              />
              <div>
                <div className="mt--50 mb--50">
                  <div className="student-profile py-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="card shadow-sm">
                            <div className="card-header bg-transparent text-center">
                              <img
                                width="130px"
                                src={profileImg}
                                alt="profileImg"
                              />
                              <h3 className="text-black">{userData?.name}</h3>
                            </div>
                            <div className="card-body">
                              <p className="mb-0">
                                <strong className="pr-1">Referral ID : </strong>
                                {userData?.referral_id}
                              </p>
                              <p className="mb-0">
                                <strong className="pr-1">Email : </strong>
                                {userData?.email}
                              </p>
                              <p className="mb-0">
                                <strong className="pr-1">Mobile No : </strong>
                                {userData?.mobile}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="card shadow-sm">
                            <div className="card-header bg-transparent border-0">
                              <h3 className="mb-0 text-black">
                                <i className="far fa-clone pr-1"></i>General
                                Information
                              </h3>
                            </div>
                            <div className="card-body pt-0">
                              <table className="table table-bordered">
                                <tr>
                                  <th width="30%">Active Package Name:</th>
                                  <td width="2%">:</td>
                                  <td>{userData?.free_trial_status === 1 ? "7 days free trial" : userData?.free_trial_status === 0 && userData?.subscribed_status === 1 ? `You get ${userData?.subcription_month} month subcription` : "Your subcription has expired"}</td>
                                </tr>
                                <tr>
                                  <th width="30%">Trial Start Date</th>
                                  <td width="2%">:</td>
                                  <td>{userData?.ft_started_date}</td>
                                </tr>
                                <tr>
                                  <th width="30%">Trial End Date</th>
                                  <td width="2%">:</td>
                                  {
                                    userData?.free_trial_status === 1 ?
                                      <td>{userData?.ft_last_date}</td> :
                                      <td>{userData?.ft_last_date} <span style={{ color: "red" }}>(Your trial has expired**)</span></td>
                                  }

                                </tr>
                                <tr>
                                  <th width="30%">Subscription Start Date</th>
                                  <td width="2%">:</td>
                                  <td>
                                    {
                                      userData?.subscribed_status === 1 ?
                                        userData?.subscribed_started_date :
                                        <button
                                          style={{
                                            color: "blue",
                                            border: "1px solid #ccc",
                                          }}
                                        >
                                          <Link to={"/price"}>Subscribe Now</Link>
                                        </button>
                                    }

                                  </td>
                                </tr>
                                <tr>
                                  <th width="30%">Subscription End Date</th>
                                  <td width="2%">:</td>
                                  <td>
                                    {
                                      userData?.subscribed_status === 1 ?
                                        userData?.subscribed_last_date :
                                        "Not"
                                    }

                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="mt--60 mb--60 container">
                  <form className="contact-form-1" action="" id="contact_form">
                    <div
                      className="form-group"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HiOutlinePencilSquare
                        onClick={() => handleEditClick("name")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "30%",
                          fontSize: "30px",
                          cursor: "pointer",
                          zIndex: "999",
                          borderLeft: "1px solid #e7e2e2",
                          paddingLeft: "10px",
                        }}
                      />
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                        style={{
                          border: !borrderColor.name
                            ? "2px solid hsla(0, 0%, 100%, 0.1)"
                            : "2px solid #0ADC9C",
                        }}
                        disabled={!editing.name}
                      />
                    </div>

                    <div
                      className="form-group"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HiOutlinePencilSquare
                        onClick={() => handleEditClick("email")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "30%",
                          fontSize: "30px",
                          cursor: "pointer",
                          zIndex: "999",
                          borderLeft: "1px solid #e7e2e2",
                          paddingLeft: "10px",
                        }}
                      />
                      <input
                        style={{
                          border: !borrderColor.email
                            ? "2px solid hsla(0, 0%, 100%, 0.1)"
                            : "2px solid #0ADC9C",
                        }}
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required
                        value={formData.email}
                        onChange={handleChange}
                        disabled={!editing.email}
                      />
                    </div>

                    <div
                      className="form-group"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HiOutlinePencilSquare
                        onClick={() => handleEditClick("phone")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "30%",
                          fontSize: "30px",
                          cursor: "pointer",
                          zIndex: "999",
                          borderLeft: "1px solid #e7e2e2",
                          paddingLeft: "10px",
                        }}
                      />
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        required
                        value={formData.phone}
                        onChange={handleChange}
                        style={{
                          border: !borrderColor.phone
                            ? "2px solid hsla(0, 0%, 100%, 0.1)"
                            : "2px solid #0ADC9C",
                        }}
                        disabled={!editing.phone}
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HiOutlinePencilSquare
                        onClick={() => handleEditClick("whatsapp")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "30%",
                          fontSize: "30px",
                          cursor: "pointer",
                          zIndex: "999",
                          borderLeft: "1px solid #e7e2e2",
                          paddingLeft: "10px",
                        }}
                      />
                      <input
                        type="text"
                        name="whatsapp"
                        placeholder="Watsapp Number"
                        required
                        value={formData.whatsapp}
                        onChange={handleChange}
                        style={{
                          border: !borrderColor.whatsapp
                            ? "2px solid hsla(0, 0%, 100%, 0.1)"
                            : "2px solid #0ADC9C",
                        }}
                        disabled={!editing.whatsapp}
                      />
                    </div>
                    <div className="form-group">
                      <button className="btn-default btn-large">
                        {loading ? "Submitting..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </>
        )}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Profile;
