import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";

const ChangePassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://cms.forexsupersignal.com/api/send-reset-password-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      setLoading(false);
      console.log(data); // handle the response as needed
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <HeaderOne
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="header-transparent"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          width: "100%",
        }}
      >
        <div className="container">
          <form className="row align-items-center justify-content-center">
            <div
              className="col-lg-4"
              style={{
                backgroundColor: "#FFF",
                padding: "2em",
                borderRadius: "15px",
              }}
            >
              <h4
                style={{
                  color: "#222",
                  textAlign: "center",
                  paddingBottom: "0.8em",
                }}
              >
                Create New Password
              </h4>
              <div className="form-group">
                <input
                  style={{ border: "1px solid #ccc" }}
                  type="text"
                  placeholder="Enter new password"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  style={{ border: "1px solid #ccc" }}
                  type="text"
                  placeholder="Confirm new password"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button
                  onClick={handleResetPassword}
                  className="btn-default-two btn-small"
                  style={{
                    width: "100%",
                    height: "44px",
                    lineHeight: "40px",
                  }}
                >
                  {loading ? "Submitting..." : "Submit Now"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <FooterOne />
      <Copyright />
    </>
  );
};
export default ChangePassword;
