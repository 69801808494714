import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../lib/api";
import { businessApi } from "../../lib/api";
const Logo = ({ image, image2 }) => {
    const [businessApiData, setBusinessApiData] = useState([])
    useEffect(() => {
        // Function to fetch data from both APIs
        const fetchData = async () => {
            try {
                // Calling businessApi and homeApi simultaneously
                const [businessData] = await Promise.all([
                    businessApi()
                ]);

                // Updating state with the API data
                setBusinessApiData(businessData[0]);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchData();
    }, []);
    return (
        <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img className="" src={`${IMAGE_BASE_URL + businessApiData?.left_image}`} alt="header logo" />
                <img className="logo-dark" src={`${IMAGE_BASE_URL + businessApiData?.left_image}`} alt="header logo" />
            </Link>
        </div>
    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
