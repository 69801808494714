import React from "react";
import Typed from "react-typed";

const AboutOne = ({ title, des, img, imgTag, about_title }) => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img className="w-100" src={img} alt={imgTag} />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  {about_title} <br />{" "}
                  <Typed
                    className="theme-gradient"
                    strings={["Trading"]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: des }}></div>
                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
