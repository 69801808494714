import React, { useState, useEffect } from "react";
import SEO from "../common/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import Separator from "../elements/separator/Separator";
import AboutOne from "../elements/about/AboutOne";
import CalltoActionEight from "../elements/calltoaction/CalltoActionEight";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import CalltoActionCustom from "../elements/calltoaction/CalltoActionCustom";
import { IMAGE_BASE_URL, businessApi, aboutApi } from "../lib/api";
import LoadingComponent from "../components/LoadingComponent";
import homebanner from "../../src/assets/images/home/homebanner.png";

const AboutUs = () => {
  const [modalShow, setModalShow] = useState(false);
  const [businessApiData, setBusinessApiData] = useState([]);
  const [aboutApiData, setAboutApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const modalHidefun = () => {
    setModalShow(false);
  };
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [businessData, abutData] = await Promise.all([
          businessApi(),
          aboutApi(),
        ]);

        // Updating state with the API data
        setBusinessApiData(businessData[0]);
        setAboutApiData(abutData[0]);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <SEO businessApiData={businessApiData} />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
          modalHidefun={() => modalHidefun()}
          modalShowSignIn={modalShow}
        />
        {/* Start Slider Area  */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div
              className="slider-area slider-style-3 height-850"
              style={{
                backgroundImage: `url(${homebanner})`,
              }}
            >
              <div className="container">
                <div className="row row--30 align-items-center">
                  <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                    <div className="inner text-start">
                      <h1 className="title theme-gradient">
                        {aboutApiData?.title}
                      </h1>
                      <p className="description">{aboutApiData?.short_dec}</p>
                      <div className="button-group mt--40">
                        <a
                          className="btn-default btn-small btn-icon"
                          href="#"
                          onClick={() => setModalShow(true)}
                        >
                          Sign In{" "}
                          <i className="icon">
                            <FiArrowRight />
                          </i>
                        </a>
                        <a
                          className="btn-default-two btn-small"
                          href="#"
                          onClick={() => setModalShow(true)}
                        >
                          Sign Up{" "}
                          <i className="icon">
                            <FiArrowRight />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="order-1 order-lg-2 col-lg-5">
                    <div className="pt--40">
                      <img
                        src={IMAGE_BASE_URL + aboutApiData?.about_banner}
                        alt={aboutApiData?.banner_alt_tag}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider Area  */}
            <Separator />

            <AboutOne
              about_title={aboutApiData?.about_title}
              title={aboutApiData?.title}
              des={aboutApiData?.long_des}
              img={IMAGE_BASE_URL + aboutApiData?.about_img}
              imgTag={aboutApiData?.about_img_tag}
            />

            <Separator />
            <CalltoActionCustom aboutApiData={aboutApiData} />
            <CalltoActionEight aboutApiData={aboutApiData} />
          </>
        )}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;
