import React, { useState, useEffect } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import FooterOne from "../../common/footer/FooterOne";
import Copyright from "../../common/footer/Copyright";
import HeaderTopNews from "../../common/header/HeaderTopNews";
import HeaderOne from "../../common/header/HeaderOne";
import Separator from "../separator/Separator";
import SectionTitleTwo from "../sectionTitle/SectionTitleTwo";
import { IMAGE_BASE_URL } from "../../lib/api";
import { businessApi, allHeadingApi } from "../../lib/api";
const Contact = () => {
  const [businessApiData, setBusinessApiData] = useState([]);
  const [allHeadingApiData, setAllHeadingApiData] = useState([]);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      try {
        // Calling businessApi and homeApi simultaneously
        const [businessData, allHeadingData] = await Promise.all([
          businessApi(),
          allHeadingApi(),
        ]);

        // Updating state with the API data
        setBusinessApiData(businessData[0]);
        setAllHeadingApiData(allHeadingData[0]);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <SEO title="Contact" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        <Separator />
        <BreadcrumbOne
          title={allHeadingApiData?.contact_banner_title}
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        />
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitleTwo
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Contact Form"
                    title={allHeadingApiData?.contact_page_title}
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default Contact;
