import { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAuth } from "../../redux/action/loginAuth";
import axios from "axios";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";
import { FaPinterest } from "react-icons/fa";
import { TbBrandYoutube } from "react-icons/tb";

const HeaderTopNews = ({ handleSignInModal }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loginAuth = useSelector((state) => state.loginAuth);
  const [deactive, setClass] = useState("");

  const callBackFunction = () => {
    const userToken = localStorage.getItem("usertoken");
    // Axios request configuration
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: "https://cms.forexsupersignal.com/api/forex/user/loggeduser",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    // Make the API request using Axios
    axios(axiosConfig)
      .then((res) => {
        // Handle the response
        // setApiResponse(response.data);
        callBackFunction();
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };
  const handleLogout = () => {
    setLoading(true);
    const userToken = localStorage.getItem("usertoken");
    // Axios request configuration
    const axiosConfig = {
      method: "get", // Use 'post' if you are making a POST request
      url: "https://cms.forexsupersignal.com/api/forex/user/loggeduser",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    // Make the API request using Axios
    axios(axiosConfig)
      .then((res) => {
        setLoading(false);
        // Handle the response
        // setApiResponse(response.data);
        localStorage.setItem("usertoken", "");
        dispatch(
          userAuth({
            loginAuth: false,
            token: "",
            userData: "",
          })
        );

        navigate("/");
      })
      .catch((error) => {
        // Handle errors
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  return (
    <Navbar className="">
      <Container>
        {/* <Navbar.Brand href="#home">Navbar with text</Navbar.Brand> */}
        <Navbar.Toggle />
        <Navbar.Collapse className="">
          <div className="d-flex align-items-center justify-content-between" style={{ width: "100%" }}>
            <div className="social-icon-wrapper">
              <ul className="social-icon social-default icon-naked">
                <li><Link to="https://www.pinterest.com/forexsupersignal/" target="_blank"><FaPinterest /></Link></li>
                <li><Link to="https://www.youtube.com/channel/UC9GSsTYFinarZ0X96PnMQzQ" target="_blank"><TbBrandYoutube /></Link></li>
                <li><Link to="https://twitter.com/forexsuper012" target="_blank"><FiTwitter /></Link></li>
                <li><Link to="https://www.instagram.com/forexsuper012/" target="_blank"><FiInstagram /></Link></li>
                <li><Link to="https://www.instagram.com/forexsuper012/" target="_blank"><FiLinkedin /></Link></li>
              </ul>
            </div>
            <div className="justify-content-end" >
              <div>
                {loginAuth ? (
                  <Navbar.Text className="text-white">
                    <Link to={"#"} className="text-white">
                      Welcome to Forexsupersignal
                    </Link>
                    {" | "}
                    <Link
                      to={"#"}
                      onClick={() => handleLogout()}
                      className="text-white"
                    >
                      {loading ? "Please wait ..." : "Logout"}
                    </Link>
                  </Navbar.Text>
                ) : (
                  <Navbar.Text className="text-white">
                    <Link
                      className="text-white"
                      to={"#"}
                      onClick={() => handleSignInModal()}
                    >
                      Sign In
                    </Link>
                    {" / "}
                    <Link
                      className="text-white"
                      to={"#"}
                      onClick={() => handleSignInModal()}
                    >
                      Sign Up
                    </Link>
                  </Navbar.Text>
                )}
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
};

export default HeaderTopNews;
